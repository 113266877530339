import { AsButton, reactSelectStyles } from "app/subframe";
import * as React from "react";
import { useClientImportCsv } from "./useClientImportCsv";
import { Path } from "app/path";
import { UploadCsv } from "./components/uploadCsv";
import { formatBytes } from "app/utils/formatBytes";
import { Divider } from "@nextui-org/react";
import Select from "react-select";
import { Icon } from "@subframe/core";

export const ClientImportCsv: React.FC = () => {
  const {
    navigate,
    getRootProps,
    getInputProps,
    csvFile,
    csvData,
    headers,
    allSetFields,
    selectedMappings,
    setSelectedMappings,
    getMatchedPreviewData,
    handleImport,
  } = useClientImportCsv();
  const fileHasBeenUploaded = !!(csvFile && csvData);

  return (
    <div className="max-w-[1000px] mx-auto w-full px-4 mt-12">
      <AsButton
        text={"Back to client portal"}
        leadingIcon={"FeatherArrowLeft"}
        variant="secondary"
        onClick={() => navigate(Path.CLIENTS)}
      />
      <p className="text-2xl text-neutral-700 mt-4 mb-6">Upload CSV File</p>
      <div className="flex flex-col gap-4 text-neutral-700 mb-10">
        <UploadCsv
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          csvFile={csvFile}
          fileHasBeenUploaded={fileHasBeenUploaded}
        />
      </div>

      {fileHasBeenUploaded && headers.length > 0 && (
        <div>
          <div className="grid grid-cols-3 py-8">
            <div className="col-span-1">
              <p>AllSet Data Fields</p>
            </div>
            <div className="col-span-1">
              <p>Import Data</p>
            </div>
            <div className="col-span-1">
              <p>Import Sample</p>
            </div>
          </div>
          <Divider />

          {allSetFields.map((header, index) => (
            <React.Fragment key={header}>
              <div className="grid grid-cols-3 gap-6">
                {/* AllSet Data Fields Column */}
                <div className="col-span-1 py-8 h-[180px]">
                  <p className="text-sm font-medium">{header}</p>
                </div>

                {/* Select Fields Column */}
                <div className="col-span-1 space-y-2 h-[100px] py-4">
                  <Select
                    styles={{
                      ...reactSelectStyles({}),
                    }}
                    value={
                      selectedMappings[header]
                        ? {
                            value: selectedMappings[header],
                            label: selectedMappings[header],
                          }
                        : {
                            value: null,
                            label: "Do not import",
                          }
                    }
                    onChange={(option) => {
                      setSelectedMappings((prev) => ({
                        ...prev,
                        [header]:
                          option?.value === null ? "" : option?.value || "",
                      }));
                    }}
                    options={[
                      { value: null, label: "Do not import" },
                      ...headers.map((header) => ({
                        value: header,
                        label: header,
                      })),
                    ]}
                  />
                </div>

                {/* Import Sample Column */}
                <div className="col-span-1 h-[100px] py-4">
                  <div className="flex flex-col gap-2 text-sm text-success-800">
                    {getMatchedPreviewData(header)?.map((value, index) => (
                      <p key={index}>{value || "N/A"}</p>
                    ))}
                  </div>
                </div>
              </div>
              <Divider className="my-0" />
            </React.Fragment>
          ))}

          <div className="flex flex-col gap-6 justify-center items-center mt-10 mb-20">
            <div className="flex items-center gap-2">
              <Icon
                name="FeatherCheckCircle2"
                className="h-6 text-success-600 scale-125"
              />
              <p className="text-success-600 font-medium">
                {csvData?.length} clients detected
              </p>
            </div>
            <AsButton
              text="Import Data"
              variant="default"
              leadingIcon="FeatherUpload"
              size="lg"
              className="w-full max-w-[300px]"
              onClick={() => handleImport()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
