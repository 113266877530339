import * as React from "react";
import { perPageList, sortByList, useClients } from "./useClients";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  PlusIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { ClientTable } from "./components/clientTable/clientTable";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { Drawer } from "app/components/drawer/drawer";
import { AddClient } from "./components/drawer/addClient/addClient";
import { UpdateClient } from "./components/drawer/updateClient/updateClient";
import { Loading } from "app/components/loading/loading";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { AsButton, AsIconButton, Badge } from "app/subframe";
import { EmptyState } from "app/components/emptyState";
import { BlackListDrawer } from "./components/blacklistDrawer/blacklistDrawer";
import { Path } from "app/path";
import { Icon } from "@subframe/core";

export interface ClientsProps {}

export const Clients: React.FC<ClientsProps> = ({}) => {
  const {
    clients,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    shouldShowClientDrawer,
    setShouldShowClientDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
    totalCount,
    shouldShowBlacklistDrawer,
    setShouldShowBlacklistDrawer,
    onBlacklistAdd,
    onBlacklistRemove,
    navigate,
    account,
    employees,
    managerIdsToFilter,
    setManagerIdsToFilter,
    fetchClients,
  } = useClients();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <div
      className={classNames("flex flex-col p-4 gap-4 mobile:p-2 mobile:gap-2", {
        "h-[85dvh]": isBelowSm && shouldShowClientDrawer,
      })}
    >
      {isBelowSm && (
        <div className="flex items-center gap-2">
          <span className="text-[20px] font-[500] leading-[30px] text-neutral-700">
            Clients
          </span>
          <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-700">
            {totalCount} clients
          </span>
        </div>
      )}
      <BlackListDrawer
        shouldShowBlacklistDrawer={shouldShowBlacklistDrawer}
        setShouldShowBlacklistDrawer={setShouldShowBlacklistDrawer}
        account={account}
        onBlacklistAdd={onBlacklistAdd}
        onBlacklistRemove={onBlacklistRemove}
      />
      <Drawer
        isOpen={shouldShowClientDrawer}
        setIsOpen={setShouldShowClientDrawer}
        backdrop={false}
      >
        {clientIdParam ? (
          <UpdateClient
            clientId={Number(clientIdParam)}
            setCloseParent={setShouldShowClientDrawer}
            fetchClients={fetchClients}
          />
        ) : (
          <AddClient
            setCloseParent={setShouldShowClientDrawer}
            key={Date.now()}
          />
        )}
      </Drawer>
      <div className="flex items-center justify-between mobile:pb-2">
        {isAboveSm && (
          <div className="flex gap-4 items-center">
            <AsButton
              variant="neutral-secondary"
              size="md"
              onClick={() => onOpenNewClientDrawer()}
            >
              <PlusIcon className="w-4 h-4 stroke-black" />
              <p className="font-semibold ">Add Client</p>
            </AsButton>
            <p className="text-zinc-500">{clients.length} clients</p>
          </div>
        )}
        <div className={"flex justify-between mobile:w-full"}>
          {isBelowSm && (
            <AsIconButton
              variant="ghost"
              leadingIcon={"FeatherUserX"}
              size="sm"
              disabled={false}
              onClick={() => setShouldShowBlacklistDrawer(true)}
            />
          )}
          <div className="flex gap-10 items-center">
            {isAboveSm && (
              <AsButton
                variant="ghost"
                leadingIcon={"FeatherUserX"}
                text="Blacklist"
                onClick={() => setShouldShowBlacklistDrawer(true)}
                size="sm"
                loader={false}
                disabled={false}
              />
            )}
            {isAboveSm && (
              <AsButton
                variant="secondary"
                leadingIcon={"FeatherUserPlus"}
                text="Import clients"
                onClick={() => navigate(Path.CLIENT_IMPORT_CSV)}
                size="sm"
                loader={false}
                disabled={false}
              />
            )}
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  trailingIcon="FeatherChevronDown"
                  text="Sort"
                  className="text-neutral-900 hover:bg-millet-500"
                  size="sm"
                  loader={false}
                  disabled={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {sortByList.map((sb) => (
                  <DropdownItem
                    key={sb.value}
                    onClick={() => setSortBy(sb)}
                    className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                    endContent={
                      <CheckIcon
                        className={classNames("w-3 h-3 stroke-black", {
                          hidden: sb !== sortBy,
                        })}
                      />
                    }
                  >
                    {titleize(sb.title)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  leadingIcon={null}
                  trailingIcon="FeatherChevronDown"
                  className="text-neutral-900 hover:bg-millet-500"
                  text={`${rowsPerPage} per page`}
                  size="xs"
                  loader={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {perPageList.map((perPage) => (
                  <DropdownItem
                    key={perPage}
                    onClick={() => setRowsPerPage(perPage)}
                    className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                  >
                    {perPage} per page
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {isAboveSm && (
              <div className="flex items-center gap-2 relative">
                <p className="text-sm text-default-500">Filter by</p>
                <Dropdown>
                  <DropdownTrigger>
                    <AsButton
                      variant="ghost"
                      leadingIcon={null}
                      trailingIcon="FeatherChevronDown"
                      className="text-neutral-900 hover:bg-millet-500"
                      text={"Manager"}
                      size="sm"
                      loader={false}
                    />
                  </DropdownTrigger>
                  <DropdownMenu selectionMode="multiple" closeOnSelect={false}>
                    {/* employeeIdsToFilter,
                 setManagerIdsToFilter, */}
                    {employees.map((employee) => {
                      const isSelected = managerIdsToFilter.includes(
                        employee.id
                      );
                      return (
                        <DropdownItem
                          key={employee.id}
                          className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                          onClick={() => {
                            // Check if the employee ID already exists in the filter
                            if (isSelected) {
                              // If it exists, remove it
                              setManagerIdsToFilter(
                                managerIdsToFilter.filter(
                                  (id) => id !== employee.id
                                )
                              );
                            } else {
                              // If it doesn't exist, add it
                              setManagerIdsToFilter([
                                ...managerIdsToFilter,
                                employee.id,
                              ]);
                            }
                          }}
                        >
                          <div className="flex gap-1 items-center">
                            <Icon
                              name="FeatherCheck"
                              className={classNames({
                                invisible: !isSelected,
                              })}
                            />
                            {employee.name}
                          </div>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
                {managerIdsToFilter.length > 0 && (
                  <Badge
                    variant="success"
                    className="absolute h-4 w-4 -top-1 -right-1 rounded-full z-10"
                  >
                    {managerIdsToFilter.length}
                  </Badge>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {clients.length === 0 ? (
        <div className="w-full h-[calc(100dvh-200px)] flex justify-center items-center flex-col gap-10">
          <EmptyState />
          <AsButton
            text={"Add a new client"}
            onClick={() => onOpenNewClientDrawer()}
          />
        </div>
      ) : (
        <ClientTable
          rowsPerPage={rowsPerPage}
          onClientRowClick={onClientRowClick}
        />
      )}
      {isBelowSm && (
        <AsButton
          variant="brand"
          leadingIcon="FeatherPlus"
          trailingIcon={null}
          text="Add client"
          size="md"
          loader={false}
          shadow
          onClick={() => onOpenNewClientDrawer()}
          className="fixed bottom-24 self-center z-50"
        />
      )}
    </div>
  );
};
