import { DialogLayout, AsButton } from "app/subframe";
import * as React from "react";
import { SubscriptionTierType } from "app/dictionaries/plans";
import { titleize } from "app/utils/string";
import { motion } from "framer-motion";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";

export interface SubscriptionSuccessfulModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  newlySubscribedPlan: SubscriptionTierType;
}

export const SubscriptionSuccessfulModal: React.FC<
  SubscriptionSuccessfulModalProps
> = ({ open, onOpenChange, newlySubscribedPlan }) => {
  const query = useQuery();
  const navigate = useNavigate();

  const onClose = () => {
    onOpenChange(false);
    query.delete("stripe-subscription-successful-plan");
    const currentLocation = window.location.pathname;
    navigate(currentLocation);
  };

  return (
    <DialogLayout
      open={open}
      onOpenChange={onOpenChange}
      className="z-[1000] rounded-xl overflow-hidden"
    >
      <div className="flex flex-col items-center p-8 text-center bg-white">
        <div className="mb-6 relative w-16 h-16">
          <div className="relative">
            {/* Green circle background */}
            <motion.div
              className="absolute inset-0 rounded-full bg-success-500"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                duration: 0.5,
                ease: "easeOut",
              }}
            />

            {/* Checkmark */}
            <motion.svg
              className="w-16 h-16 relative z-10"
              viewBox="0 0 52 52"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                className="text-white"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 26l8 8 16-16"
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ pathLength: 1, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  duration: 0.5,
                  ease: "easeInOut",
                }}
              />
            </motion.svg>
          </div>
        </div>

        <motion.h2
          className="text-2xl font-bold mb-3 text-gray-800"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          You're all set!
        </motion.h2>

        <motion.p
          className="text-gray-600 mb-8 max-w-sm"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
        >
          You're now subscribed to the{" "}
          <span className="font-semibold text-gray-800">
            {titleize(newlySubscribedPlan)}
          </span>
        </motion.p>

        <motion.div
          className="w-full"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <AsButton
            onClick={() => {
              onClose();
            }}
            text="Continue"
            variant="default"
            size="md"
            className="w-full"
          />
        </motion.div>
      </div>
    </DialogLayout>
  );
};
