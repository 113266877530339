import { selectAccount, updateAccount } from "app/redux/accountSlice";
import { OnboardingStep, updateOnboarding } from "app/redux/onboardingSlice";
import { selectUser } from "app/redux/userSlice";
import { getFormValues } from "app/utils/getFormValues";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useBusinessInfo = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: string) => void;
}) => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  // const [resellers, setResellers] = useState<any>([]);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const {
      businessName,
      website,
      // resellerId
    } = getFormValues(event.target);

    try {
      setLoading(true);
      await dispatch(
        updateAccount({
          name: businessName,
          website,
          // resellerId: Number(resellerId),
        }) as any
      ).unwrap();
      await dispatch(
        updateOnboarding({
          step: OnboardingStep.HOW_MANY_SERVICE_PROVIDERS,
        }) as any
      ).unwrap();
      setCurrentStep(OnboardingStep.HOW_MANY_SERVICE_PROVIDERS);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.data?.error?.length) {
        toast.error(`Business ${error?.data?.error?.[0]}`);
      }
      console.error(error);
    }
  };

  // const fetchResellers = async () => {
  //   try {
  //     const fetchedResellers = await dispatch(getResellers() as any).unwrap();
  //     setResellers(fetchedResellers);
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchResellers();
  // }, []);

  return {
    onSubmit,
    loading,
    // resellers,
    account,
    user,
  };
};
