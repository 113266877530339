import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableRow,
} from "@nextui-org/react";
import { Avatar } from "app/components/avatar/avatar";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { truncate } from "lodash";
import * as React from "react";
import { TableHeader } from "react-stately";
import { useResellerAccountsTable } from "./useResellerAccountsTable";
import { AsBadge, AsIconButton, Loader } from "app/subframe";
import { getAccountStatusInfo } from "app/reseller/home/useHome";

export interface ResellerAccountsTableProps {
  onAccountImpersonate: (id: number) => void;
  rowsPerPage: number;
}

export const ResellerAccountsTable: React.FC<ResellerAccountsTableProps> = ({
  rowsPerPage,
  onAccountImpersonate,
}) => {
  const { totalCount, page, onPageChange, sortedItems, onImpersonate } =
    useResellerAccountsTable({
      onAccountImpersonate,
    });

  const { isBelowSm } = useBreakpoint("sm");

  const renderCell = (account: any, columnKey: any) => {
    const cellValue = account[columnKey];

    switch (columnKey) {
      case "id":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      case "name":
        return (
          <div className="flex items-center gap-2">
            <Avatar
              src={account.logo}
              name={cellValue}
              type="character"
              className="rounded w-6 h-8"
            />
            <p className="text-gray-800 text-sm">
              {isBelowSm
                ? truncate(cellValue, { length: 6, omission: ".." })
                : cellValue}
            </p>
          </div>
        );

      case "email":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      case "status":
        const { badgeType, description } = getAccountStatusInfo(account);

        return (
          <div className="flex flex-col">
            <AsBadge variant={badgeType as any} className="w-fit">
              {description}
            </AsBadge>
          </div>
        );
      case "actions":
        return (
          <Dropdown
            classNames={{
              content: "border rounded-lg border-neutral-50 !p-0",
            }}
          >
            <DropdownTrigger>
              <AsIconButton
                leadingIcon="FeatherMoreVertical"
                variant="ghost"
                color="default"
                size="sm"
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem
                key="impersonate"
                className="data-[hover=true]:!bg-millet-500"
                onClick={() => onImpersonate(account.id)}
                disableAnimation
              >
                <p className="p-1">Impersonate Business</p>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        );
      default:
        return cellValue;
    }
  };

  const pages = Math.ceil(totalCount / rowsPerPage);

  return (
    <Table
      // bottomContent={
      //   <div className="flex w-full justify-center pt-8">
      //     <Pagination
      //       isCompact
      //       showControls={pages > 1}
      //       variant="light"
      //       color="default"
      //       total={pages}
      //       page={page}
      //       onChange={onPageChange}
      //     />
      //   </div>
      // }
      isHeaderSticky
      classNames={{
        base: isBelowSm ? "h-[78dvh] pb-6" : "h-auto",
        wrapper: "min-h-[222px] border-none shadow-none p-0",
        thead: "bg-white shadow-none border-none !rounded-none",
        th: "bg-transparent font-normal",
        tr: "h-12 !shadow-none",
      }}
    >
      <TableHeader>
        <TableColumn key="id">#</TableColumn>
        <TableColumn key="name">Business name</TableColumn>
        <TableColumn key="email">Email</TableColumn>
        <TableColumn key="status">Status</TableColumn>
        <TableColumn key="actions" align="end" className="w-12">
          {}
        </TableColumn>
      </TableHeader>
      <TableBody items={sortedItems}>
        {(item: any) => (
          <TableRow key={item.id}>
            {(columnKey: any) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
