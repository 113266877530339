import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// ✅ Default Firebase App (For Main User Authentication)
const mainApp = initializeApp(firebaseConfig);
const mainAuth = getAuth(mainApp);

// ✅ Default Export (Unchanged)
export default mainAuth;

/**
 * ✅ Helper function to create & return a secondary Firebase Auth instance.
 * @param {string} instanceName - Unique name for the Firebase app instance.
 * @returns {Auth} Firebase Auth instance.
 */
export const getSecondaryAuthInstance = (instanceName: string) => {
  if (getApps().some((app) => app.name === instanceName)) {
    return getAuth(getApp(instanceName)); // Return existing instance
  } else {
    const secondaryApp = initializeApp(firebaseConfig, instanceName); // Create new instance
    return getAuth(secondaryApp);
  }
};
