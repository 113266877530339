import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { findIndex } from "lodash";

export enum AvailabilityTypes {
  WEEKLY_AVAILABILITY = "WeeklyAvailability",
  AVAILABILITY_OVERRIDE = "AvailabilityOverride",
}

export enum AvailabilityableTypes {
  USER = "User",
  SHOP = "Shop",
}

export enum AvailabilityStatusTypes {
  UNSCHEDULED = "unscheduled",
  SCHEDULED = "scheduled",
  TIMEOFF = "timeOff",
}

export interface Availability {
  id: number;
  day: string;
  date: string;
  timeStart: string;
  timeEnd: string;
  type: AvailabilityTypes;
  availabilityableId: number;
  availabilityableType: AvailabilityTypes;
  status: string;
}

export interface EmployeeAvailabilityData {
  availabilities: Availability[];
  fetchedDates: string[];
}

export interface AvailabilitiesSliceState {
  [employeeId: number]: EmployeeAvailabilityData | undefined;
}

const initialState: AvailabilitiesSliceState = {};

export const getAvailabilitiesWithOverrideByEmployeeId = createAsyncThunk(
  "availabilities/getAvailabilitiesWithOverrideByEmployeeId",
  async (
    { employeeId, date }: { employeeId: number; date: Date },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get(
        `/v1/users/${employeeId}/availabilities/weekly_with_overrides`,
        {
          params: {
            date: date.toISOString(),
            availabilityableType: "User",
            snakeCase: true,
          },
        }
      );
      return {
        employeeId,
        availabilities: data,
        fetchedDate: date.toISOString(),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateWeeklyAvailability = createAsyncThunk(
  "availabilities/updateWeeklyAvailability",
  async (
    {
      employeeId,
      availability,
    }: { employeeId: number; availability: Availability },
    thunkAPI
  ) => {
    try {
      const { data } = await http.patch(
        `/v1/users/${employeeId}/weekly_availabilities/${availability.id}`,
        availability
      );
      return { employeeId, availability: data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createAvailabilityOverride = createAsyncThunk(
  "availabilities/createAvailabilityOverride",
  async (
    {
      employeeId,
      availability,
    }: {
      employeeId: number;
      availability: Availability;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(
        `/v1/users/${employeeId}/availability_overrides`,
        availability
      );
      return { employeeId, override: data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAvailabilityOverride = createAsyncThunk(
  "availabilities/updateAvailabilityOverride",
  async (
    {
      employeeId,
      availability,
      shouldUpdateWeekly = false,
    }: {
      employeeId: number;
      availability: Availability;
      shouldUpdateWeekly?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.patch(
        `/v1/users/${employeeId}/availability_overrides/${availability.id}?should_update_weekly=${shouldUpdateWeekly}`,
        availability
      );
      return { employeeId, override: data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const availabilitiesSlice = createSlice({
  name: "availabilities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAvailabilitiesWithOverrideByEmployeeId.fulfilled,
      (
        state,
        action: PayloadAction<{
          employeeId: number;
          availabilities: Availability[];
          fetchedDate: string;
        }>
      ) => {
        const { employeeId, availabilities, fetchedDate } = action.payload;

        if (!state[employeeId]) {
          state[employeeId] = {
            availabilities: [],
            fetchedDates: [],
          };
        }

        const employeeData = state[employeeId]!;

        // Create a map of existing availabilities by date for quick lookup
        const existingAvailabilitiesByDate = employeeData.availabilities.reduce(
          (acc, avail) => {
            acc[avail.date] = avail;
            return acc;
          },
          {} as Record<string, Availability>
        );

        // For each new availability, either update existing or add new
        availabilities.forEach((availability) => {
          const existingIndex = employeeData.availabilities.findIndex(
            (a) => a.date === availability.date
          );

          if (existingIndex > -1) {
            // Update existing availability
            employeeData.availabilities[existingIndex] = availability;
          } else {
            // Add new availability
            employeeData.availabilities.push(availability);
          }
        });

        if (!employeeData.fetchedDates.includes(fetchedDate)) {
          employeeData.fetchedDates.push(fetchedDate);
        }
      }
    );
    builder.addCase(
      updateWeeklyAvailability.fulfilled,
      (
        state,
        action: PayloadAction<{
          employeeId: number;
          availability: Availability;
        }>
      ) => {
        const { employeeId, availability } = action.payload;
        if (state[employeeId]) {
          const index = state[employeeId]!.availabilities.findIndex(
            (a) => a.id === availability.id
          );
          if (index > -1) {
            state[employeeId]!.availabilities[index] = availability;
          }
        }
      }
    );
    builder.addCase(
      createAvailabilityOverride.fulfilled,
      (
        state,
        action: PayloadAction<{ employeeId: number; override: Availability }>
      ) => {
        const { employeeId, override } = action.payload;
        if (!state[employeeId]) {
          state[employeeId] = {
            availabilities: [],
            fetchedDates: [],
          };
        }
        const indexOfExistingAvailability = findIndex(
          state[employeeId]!.availabilities,
          (a) => a.date === override.date
        );

        if (indexOfExistingAvailability > -1) {
          state[employeeId]!.availabilities[indexOfExistingAvailability] =
            override;
        } else {
          state[employeeId]!.availabilities.push(override);
        }
      }
    );
    builder.addCase(
      updateAvailabilityOverride.fulfilled,
      (
        state,
        action: PayloadAction<{ employeeId: number; override: Availability }>
      ) => {
        const { employeeId, override } = action.payload;
        if (state[employeeId]) {
          const index = state[employeeId]!.availabilities.findIndex(
            (a) => a.id === override.id
          );
          if (index > -1) {
            state[employeeId]!.availabilities[index] = override;
          }
        }
      }
    );
  },
});

export const selectAvailabilities = (state: RootState) => state.availabilities;
