import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import Papa from "papaparse";
import { useDispatch } from "react-redux";
import { importClientsCSV } from "app/redux/clientsSlice";
import { Path } from "app/path";
import { LocalStorageKeys } from "app/dictionaries/localstorage";

const allSetFields = [
  "First Name",
  "Last Name",
  "Email",
  "Phone",
  "Gender",
  "Notes",
];

export const useClientImportCsv = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = React.useState<File | null>(null);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const [csvData, setCsvData] = React.useState<string[][]>([]);
  const [selectedMappings, setSelectedMappings] = React.useState<
    Record<string, string>
  >({});

  // Fuzzy matching function
  const findBestMatch = React.useCallback(
    (field: string, headers: string[]): string | null => {
      const searchPatterns: Record<string, string[]> = {
        "First Name": [
          "first name",
          "first",
          "firstname",
          "fname",
          "given name",
          "givenname",
        ],
        "Last Name": [
          "last name",
          "last",
          "lastname",
          "lname",
          "surname",
          "family name",
          "familyname",
        ],
        Email: [
          "email",
          "email address",
          "emailaddress",
          "e-mail",
          "mail",
          "contact email",
        ],
        Phone: [
          "phone",
          "phone number",
          "phonenumber",
          "telephone",
          "tel",
          "mobile",
          "cell",
          "contact number",
          "contact phone",
        ],
        Gender: ["gender", "sex", "gender identity"],
        Notes: [
          "notes",
          "comments",
          "additional info",
          "additional information",
          "remarks",
          "description",
          "client notes",
        ],
      };

      const patterns = searchPatterns[field] || [field.toLowerCase()];

      for (const header of headers) {
        const normalizedHeader = header.toLowerCase().replace(/\s+/g, "");
        for (const pattern of patterns) {
          const normalizedPattern = pattern.toLowerCase().replace(/\s+/g, "");
          if (
            normalizedHeader.includes(normalizedPattern) ||
            normalizedPattern.includes(normalizedHeader)
          ) {
            return header;
          }
        }
      }

      return null;
    },
    []
  );

  // Create preview data object from headers and first row
  const importSample = React.useMemo(() => {
    if (!headers.length || !csvData.length) return {};

    return headers.reduce((acc, header, index) => {
      acc[header] = csvData.slice(0, 3).map((row) => row[index]);
      return acc;
    }, {} as Record<string, string[]>);
  }, [headers, csvData]);

  // Set initial mappings when headers change
  React.useEffect(() => {
    if (headers.length) {
      const initialMappings = allSetFields.reduce((acc, field) => {
        const match = findBestMatch(field, headers);
        if (match) {
          acc[field] = match;
        }
        return acc;
      }, {} as Record<string, string>);
      setSelectedMappings(initialMappings);
    }
  }, [headers, findBestMatch]);

  // Get preview data based on fuzzy matching
  const getMatchedPreviewData = React.useCallback(
    (allSetField: string) => {
      const matchedHeader = selectedMappings[allSetField];
      return matchedHeader ? importSample[matchedHeader] : [];
    },
    [importSample, selectedMappings]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [".csv"] },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setCsvFile(file);
        parseCSV(file);
      }
    },
  });

  const parseCSV = (file: File) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      Papa.parse(target?.result as string, {
        complete: (result: Papa.ParseResult<string[]>) => {
          setHeaders(result.data[0]);
          setCsvData(result.data.slice(1));
        },
        skipEmptyLines: true,
      });
    };
    reader.readAsText(file);
  };

  // Generate CSV file for backend
  const generateMappedCSV = React.useCallback(() => {
    // Filter out unmapped fields but always include Email
    const validMappings = Object.entries(selectedMappings).filter(
      ([field, csvField]) =>
        field === "Email" || (csvField && csvField !== "DO_NOT_IMPORT")
    );

    if (validMappings.length === 0) {
      return null;
    }

    // If Email is not in the mappings, add it with DO_NOT_IMPORT
    if (!validMappings.some(([field]) => field === "Email")) {
      validMappings.push(["Email", "DO_NOT_IMPORT"]);
    }

    // Create headers and data for the new CSV
    const newHeaders = validMappings.map(([allSetField]) => allSetField);
    const newData = csvData.map((row) => {
      return validMappings.map(([_, csvField]) => {
        if (csvField === "DO_NOT_IMPORT") return "";
        const index = headers.indexOf(csvField);
        return index !== -1 ? row[index] : "";
      });
    });

    // Generate CSV string
    const csvString = Papa.unparse({
      fields: newHeaders,
      data: newData,
    });

    // Create a Blob and File object
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const mappedCsvFile = new File([blob], "mapped_clients.csv", {
      type: "text/csv",
      lastModified: new Date().getTime(),
    });

    return {
      file: mappedCsvFile,
      preview: {
        headers: newHeaders,
        firstRow: newData[0] || [],
        totalRows: newData.length,
      },
    };
  }, [selectedMappings, csvData, headers]);

  // Handle import action
  const handleImport = React.useCallback(async () => {
    try {
      const mappedData = generateMappedCSV();

      if (!mappedData) {
        throw new Error("No valid mappings found");
      }

      // Create FormData for file upload
      const formData = new FormData();
      formData.append("file", mappedData.file);
      formData.append("preview", JSON.stringify(mappedData.preview));

      // Dispatch the import action
      const result = await dispatch(importClientsCSV(formData) as any).unwrap();

      // Store the import ID and navigate to status page
      localStorage.setItem(LocalStorageKeys.CSV_IMPORT_JOB_ID, result.id);
      navigate(Path.CLIENT_IMPORT_CSV_STATUS);
    } catch (error) {
      console.error("Import error:", error);
    }
  }, [generateMappedCSV, dispatch, navigate]);

  return {
    navigate,
    csvFile,
    csvData,
    headers,
    setCsvFile,
    getRootProps,
    getInputProps,
    allSetFields,
    selectedMappings,
    setSelectedMappings,
    getMatchedPreviewData,
    handleImport,
  };
};
