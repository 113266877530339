import { AsButton, AsInput } from "app/subframe";
import { PaymentModalHeader } from "app/subframe";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { round } from "lodash";
import * as React from "react";

export interface PrepaidCcCheckoutProps {
  account: any;
  initialTotalCents: number;
  recordPaidPayment: (event: any) => void;
  setCreditCardOption: (mode: any) => void;
  isRecordingPayment: boolean;
  setPayWithMode: (mode: any) => void;
}

export const PrepaidCcCheckout: React.FC<PrepaidCcCheckoutProps> = ({
  account,
  recordPaidPayment,
  setCreditCardOption,
  initialTotalCents,
  isRecordingPayment,
  setPayWithMode,
}) => {
  const { isBelowSm } = useBreakpoint("sm");

  const totalPainInputComponent = React.useCallback(
    () => (
      <div className="flex items-center w-full justify-between">
        <p className="font-semibold">Total paid</p>
        <AsInput
          className="h-12 w-32 flex-none"
          leading="$"
          error={false}
          showLeadingIcon={false}
          disabled={false}
          type="number"
          defaultValue={round(initialTotalCents / 100, 2).toFixed(2) as any}
          name="total"
        />
      </div>
    ),
    [initialTotalCents]
  );

  const handleClose = React.useCallback(() => {
    if (!account?.paywallPermissions.processPayments) {
      setPayWithMode(null);
    }
    setCreditCardOption(null);
  }, [setCreditCardOption]);

  return (
    <form onSubmit={recordPaidPayment} className="flex flex-col gap-4 w-full">
      {isBelowSm ? (
        <>
          {isBelowSm && (
            <div className="fixed top-0 left-0 h-screen w-screen bg-[#171718]/80" />
          )}
          <div className="fixed bottom-0 left-0 flex h-96 flex-none flex-col items-center gap-6 rounded-t-large bg-white px-2 py-4 shadow-pop-up-shadow mobile:h-auto mobile:w-full">
            <PaymentModalHeader
              text="Record an external CC payment"
              buttonProps={{
                onClick: handleClose,
              }}
            />
            {totalPainInputComponent()}
            <AsButton
              className="h-12 w-full flex-none"
              variant="secondary"
              leadingIcon={null}
              trailingIcon={null}
              text="Record"
              size="md"
              disabled={isRecordingPayment}
              loader={isRecordingPayment}
              type="submit"
            />
          </div>
        </>
      ) : (
        <>
          <PaymentModalHeader
            text="Record a credit card payment"
            size="small"
            buttonProps={{
              onClick: handleClose,
            }}
          />
          {totalPainInputComponent()}
          <AsButton
            className="h-10 w-32 flex-none self-end"
            variant="secondary"
            text="Record"
            size="default"
            disabled={isRecordingPayment}
            loader={isRecordingPayment}
            type="submit"
          />
        </>
      )}
    </form>
  );
};
