import React from "react";
import { SectionHeader } from "app/components/sectionHeader";
import { WeekNavigator } from "app/components/weekNavigator/weekNavigator";
import { Button, Skeleton } from "@nextui-org/react";
import { useStaffHours } from "./useStaffHours";
import { AvailabilityStatusTypes } from "app/redux/availabilitiesSlice";
import PenIconSvg from "app/assets/icons/pen.svg";
import classNames from "classnames";
import { AvailabilitiesModal } from "./components/availabilitiesModal/availabilitiesModal";
import { titleize } from "app/utils/string";

export interface StaffHoursProps {
  selectedEmployee: any;
}

export const StaffHours: React.FC<StaffHoursProps> = ({ selectedEmployee }) => {
  const {
    isLoading,
    setWeekDate,
    isAvailabilitiesModalOpen,
    setIsAvailabilitiesModalOpen,
    onOpenAvailabilitiesModalClick,
    selectedAvailability,
    formattedAvailabilities,
  } = useStaffHours({
    selectedEmployee,
  });

  return (
    <>
      <AvailabilitiesModal
        isOpen={isAvailabilitiesModalOpen}
        onOpenChange={setIsAvailabilitiesModalOpen}
        availability={selectedAvailability}
      />
      <div className="flex flex-col gap-6">
        <SectionHeader title="Hours" />
        <WeekNavigator onNextWeek={setWeekDate} onPrevWeek={setWeekDate} />
        <div className="flex flex-col p-4 border rounded border-neutral-50 gap-4">
          {isLoading ? (
            <div className="flex flex-col gap-4 w-full justify-center items-center">
              {Array.from({ length: 7 }).map((_, index) => (
                <Skeleton className="rounded-lg h-14 w-full" key={index}>
                  <div className="h-24 rounded-lg bg-default-300" />
                </Skeleton>
              ))}
            </div>
          ) : (
            formattedAvailabilities.map((availability: any) => {
              const getStatusData = () => {
                switch (availability.status) {
                  case AvailabilityStatusTypes.UNSCHEDULED:
                    return {
                      blockStyle: "border-zinc-200 bg-zinc-100",
                      detailChild: (
                        <p className="text-sm text-zinc-500">Unscheduled</p>
                      ),
                    };
                  case AvailabilityStatusTypes.SCHEDULED:
                    return {
                      blockStyle: "border-[#4BB543] bg-[#D1F4E0]",
                      detailChild: (
                        <div>
                          <p className="text-sm font-semibold">
                            {availability.formattedTimeStart} -{" "}
                            {availability.formattedTimeEnd}
                          </p>
                        </div>
                      ),
                    };
                  case AvailabilityStatusTypes.TIMEOFF:
                    return {
                      blockStyle: "border-zinc-400 bg-zinc-200",
                      detailChild: (
                        <p className=" font-semibold text-sm">Time off</p>
                      ),
                    };
                }
              };
              const statusData = getStatusData();
              return (
                <div key={availability.id} className="flex items-center gap-4">
                  <div className="w-full flex gap-4 items-center">
                    <div className="flex flex-col w-6">
                      <p className="font-semibold text-sm">
                        {titleize(availability.day?.slice(0, 3))}
                      </p>
                      <p className="text-zinc-500 text-sm">
                        {availability.formattedDate}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        "flex w-full h-14 p-4 rounded border items-center justify-between gap-4",
                        statusData?.blockStyle
                      )}
                    >
                      <div className="pr-2 flex-col justify-start items-start">
                        {statusData?.detailChild}
                      </div>
                      <Button
                        isIconOnly
                        variant="light"
                        onClick={() =>
                          onOpenAvailabilitiesModalClick(availability)
                        }
                      >
                        <img src={PenIconSvg} alt="Edit" className="w-6 h-6" />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
