import { Button, Input, Spinner } from "@nextui-org/react";
import { Path } from "app/path";
import * as React from "react";
import { Link } from "react-router-dom";
import { useSignup } from "./useSignup";
import { AsButton, Checkbox } from "app/subframe";
import { BrandInput } from "app/subframe/components/BrandInput";
import { Icon, twClassNames } from "@subframe/core";
import { LogoWithName } from "app/components/logoWithName";
import { titleize } from "app/utils/string";

export interface SignupProps {}

export const Signup: React.FC<SignupProps> = ({}) => {
  const {
    loading,
    handleOnSignupSubmit,
    showPassword,
    setShowPassword,
    showPasswordConfirmation,
    setShowPasswordConfirmation,
    resellerName,
    resellerId,
    acceptsTermsAndPolicies,
    setAcceptsTermsAndPolicies,
  } = useSignup();
  return (
    <form
      onSubmit={handleOnSignupSubmit}
      className="container max-w-none flex h-screen w-full flex-col items-center justify-center gap-4 bg-millet-500 py-12 mobile:px-4 mobile:py-12"
    >
      <div className="flex w-full max-w-112 flex-col items-center justify-center gap-10 mobile:h-auto mobile:w-full mobile:flex-none">
        <div className="flex w-full flex-col items-center gap-6">
          <LogoWithName shouldScale={false} />
          <span className="text-body-bold font-body-bold text-neutral-700">
            {resellerName
              ? `Signing up under ${titleize(resellerName)}.`
              : " Create your All Set account."}
          </span>
        </div>
        <div className="flex w-full flex-col items-center gap-1 mobile:h-auto mobile:w-full mobile:flex-none">
          <BrandInput
            className="h-14 w-full flex-none"
            inputClassName="!text-sm"
            placeholder="Your email*"
            required
            textPosition="left"
            name="email"
          />
          <BrandInput
            className="h-14 w-full flex-none"
            inputClassName="!text-sm"
            placeholder="Password*"
            required
            textPosition="left"
            name="password"
            type={showPassword ? "text" : "password"}
            trailingComponent={
              <Icon
                className={twClassNames(
                  "text-body font-body text-neutral-500 inline-flex px-4"
                )}
                onClick={() => setShowPassword(!showPassword)}
                name={showPassword ? "FeatherEye" : "FeatherEyeOff"}
              />
            }
          />
          <BrandInput
            className="h-14 w-full flex-none"
            inputClassName="!text-sm"
            placeholder="Confirm password*"
            required
            textPosition="left"
            name="passwordConfirmation"
            type={showPasswordConfirmation ? "text" : "password"}
            trailingComponent={
              <Icon
                className={twClassNames(
                  "text-body font-body text-neutral-500 inline-flex px-4"
                )}
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
                name={showPasswordConfirmation ? "FeatherEye" : "FeatherEyeOff"}
              />
            }
          />
        </div>
        <div className="flex flex-col gap-4 w-full">
          <AsButton
            className="h-14 w-full flex-none"
            variant="brand"
            leadingIcon={null}
            trailingIcon={null}
            text="Continue"
            size="lg"
            loader={loading}
          />
          <div className="flex items-center gap-2 w-full">
            <Checkbox
              checked={acceptsTermsAndPolicies}
              onCheckedChange={() =>
                setAcceptsTermsAndPolicies(!acceptsTermsAndPolicies)
              }
              variant="secondary"
            />
            <span className="text-sm text-neutral-700">
              By clicking sign up, you agree to All Set's{" "}
              <a
                href="https://heyallset.com/terms"
                target="_blank"
                className="underline"
              >
                Terms
              </a>
              <span> & </span>
              <a
                href="https://heyallset.com/privacy-policy"
                target="_blank"
                className="underline"
              >
                Policies
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};
