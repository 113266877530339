import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface ClientsSliceState {
  id: number;
  name: string;
  email: string;
  key: string;
  phone: string;
  notes: any[];
  gender: string;
  avatar: string;
  archivedAt?: string;
  managers: any[];
  createdAt: string;
  updatedAt: string;
}

interface ClientsSliceReduxState {
  clients: ClientsSliceState[];
  meta: {
    totalPages: number;
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
}

const initialState = {
  clients: [],
  meta: {
    totalPages: 0,
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
  },
} as ClientsSliceReduxState;

export const getClients = createAsyncThunk(
  "clients/getClients",
  async (
    {
      page = 1,
      perPage = 30,
      sortBy = "name",
      sortOrder = "asc",
      managerIds,
    }: {
      page?: number;
      perPage?: number;
      sortBy?: string;
      sortOrder?: "asc" | "desc";
      managerIds?: number[];
    },
    thunkAPI
  ) => {
    let url = `/v1/clients?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    if (managerIds) {
      url += `&manager_ids=${managerIds}`;
    }

    try {
      const { data } = await http.get(url);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (
    {
      name,
      email,
      phone,
      gender,
      note,
      managerIds,
    }: {
      name: string;
      email: string;
      phone: string;
      gender: string;
      note: string;
      managerIds?: number[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post("/v1/clients", {
        client: {
          name,
          email,
          phone,
          gender,
          notesAttributes: [{ content: note }],
          managerIds,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (
    {
      id,
      name,
      email,
      phone,
      gender,
      note,
      avatar,
      managerIds,
    }: {
      id: number;
      name?: string;
      email?: string;
      phone?: string;
      gender?: string;
      note?: string;
      avatar?: File | null; // Change to File or null
      managerIds?: number[];
    },
    thunkAPI
  ) => {
    try {
      let data;

      if (avatar) {
        const formData = new FormData();
        formData.append("client[avatar]", avatar); // Attach the avatar file to the form data

        data = await http.patch(`/v1/clients/${id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        // If there's no avatar, send the data as JSON
        const jsonPayload = {
          client: {
            name,
            email,
            phone,
            gender,
            notesAttributes: note ? [{ content: note }] : null,
            managerIds,
          },
        };

        data = await http.patch(`/v1/clients/${id}`, jsonPayload);
      }

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const searchClients = createAsyncThunk(
  "clients/searchClients",
  async (searchQuery: string, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/clients/search?query=${searchQuery}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const archiveClient = createAsyncThunk(
  "clients/archiveClient",
  async (id: number, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/clients/${id}/archive`, {});
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const importClientsCSV = createAsyncThunk(
  "clients/importClientsCSV",
  async (formData: FormData, thunkAPI) => {
    try {
      const { data } = await http.post(
        "/v1/clients/import_csv_clients",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getImportCsvClientsJobStatus = createAsyncThunk(
  "clients/getImportCsvClientsJobStatus",
  async (importId: string, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/clients/import_csv_clients_job_status`,
        {
          params: {
            importId,
            snakeCase: true,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClients.fulfilled, (state, action) => {
      const { clients: newClients, meta } = action.payload;

      // Directly set the new sales and meta information
      state.clients = newClients;
      state.meta = meta;
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.clients.unshift(action.payload);
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      const index = state.clients.findIndex(
        (client) => client.id === action.payload.id
      );

      state.clients[index] = action.payload;
    });
    builder.addCase(archiveClient.fulfilled, (state, action) => {
      // remove client from state
      state.clients = state.clients.filter(
        (client) => client.id !== action.payload.id
      );
    });
  },
});

export const selectClients = (state: RootState) => state.clients;
