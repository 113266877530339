import { CopyButton } from "app/components/copyButton";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { AsButton, AsIconButton, AsInput, DialogLayout } from "app/subframe";
import * as React from "react";
import { useCreateMerchantModal } from "./useCreateMerchantModal";
import { parameterize } from "app/utils/string";

export interface CreateMerchantModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  fetchResellerAccounts: () => void;
}

export const CreateMerchantModal: React.FC<CreateMerchantModalProps> = ({
  isOpen,
  onOpenChange,
}: CreateMerchantModalProps) => {
  const {
    email,
    setEmail,
    onSendSignUpLink,
    isSendingSignUpLink,
    reseller,
    onCreateMerchant,
    isCreatingMerchant,
  } = useCreateMerchantModal({
    onOpenChange,
  });

  const { id: resellerId, name: resellerName, affiliateReferralId } = reseller;

  return (
    <FadeInSlide direction="bottom" className="absolute">
      <DialogLayout
        open={isOpen}
        onOpenChange={onOpenChange}
        className="z-[1000]"
      >
        <div className="flex h-192 w-192 flex-col items-center gap-6 rounded-lg bg-default-background px-6 pt-6 pb-10 shadow-dialog-shadow mobile:h-144 mobile:w-96 mobile:px-2 mobile:pt-2 mobile:pb-4">
          <div className="flex w-full items-start justify-end gap-6">
            <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center">
              <span className="text-body-bold font-body-bold text-neutral-900">
                Add a new merchant
              </span>
              <span className="font-['Inter'] text-sm font-[400] text-neutral-700">
                Choose a method below
              </span>
            </div>
            <AsIconButton
              className="h-8 w-8 flex-none"
              variant="ghost"
              leadingIcon="FeatherX"
              leadingIconClassName="text-neutral-700"
              trailingIcon={null}
              text="Label"
              size="default"
              disabled={false}
              onClick={() => {
                onOpenChange(false);
              }}
            />
          </div>
          <div className="flex w-full border-neutral-100 flex-col items-center gap-4 rounded-md border border-solid bg-neutral-25 px-3 py-4">
            <form
              onSubmit={onCreateMerchant}
              className="flex flex-col w-full gap-4"
            >
              <div className="flex w-full justify-between">
                <p className="font-medium">Sign up on their behalf</p>
                {/* {affiliateReferralId && (
                  <p className="text-sm text-neutral-400">
                    Affiliate Id: {affiliateReferralId}
                  </p>
                )} */}
              </div>
              <AsInput
                placeholder="Business name*"
                required
                name="businessName"
                className="w-full h-14"
                textPosition="left"
              />
              <AsInput
                placeholder="Email*"
                required
                name="email"
                className="w-full h-14"
                textPosition="left"
                type="email"
              />
              <div className="flex flex-col gap-1">
                <AsInput
                  placeholder="Password*"
                  required
                  name="password"
                  className="w-full h-14"
                  textPosition="left"
                />
                <p className="text-sm text-neutral-500 ml-1">
                  Must be at least 8 characters
                </p>
              </div>
              <AsInput
                placeholder="Confirm password*"
                required
                name="passwordConfirmation"
                className="w-full h-14"
                textPosition="left"
              />
              <AsButton
                variant="default"
                text="Sign up"
                size="lg"
                type="submit"
                loader={isCreatingMerchant}
                disabled={isCreatingMerchant}
              />
            </form>
          </div>
          <div className="flex w-full border-neutral-100 flex-col items-center gap-4 rounded-md border border-solid bg-neutral-25 px-3 py-4">
            <div className="flex flex-col w-full gap-4">
              <p className="font-medium">
                Send a sign up link to merchant's email
              </p>
              <AsInput
                placeholder="Enter email address"
                className="w-full h-14"
                textPosition="left"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                trailingComponent={
                  <AsButton
                    variant="secondary"
                    text="Send now"
                    loader={isSendingSignUpLink}
                    disabled={isSendingSignUpLink}
                    className="mr-1 w-[154px]"
                    size="md"
                    onClick={() => {
                      onSendSignUpLink();
                    }}
                  />
                }
              />
            </div>

            <div className="flex flex-col gap-4 w-full">
              <div className="flex items-center justify-between">
                <AsButton
                  variant="link"
                  text={`https://app.heyallset.com/signup?resellerId=${resellerId}&resellerName=${parameterize(
                    resellerName ?? ""
                  )}&aff=${affiliateReferralId}`}
                  className="underline pl-0"
                  size="md"
                  onClick={() => {
                    window.open(
                      `https://app.heyallset.com/signup?resellerId=${resellerId}&resellerName=${parameterize(
                        resellerName ?? ""
                      )}&aff=${affiliateReferralId}`,
                      "_blank"
                    );
                  }}
                  textClassName="!font-[400]"
                />
                <CopyButton
                  textToCopy={`https://app.heyallset.com/signup?resellerId=${resellerId}&resellerName=${parameterize(
                    resellerName ?? ""
                  )}&aff=${affiliateReferralId}`}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogLayout>
    </FadeInSlide>
  );
};
