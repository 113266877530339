import { Path } from "app/path";
import { searchClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import { getSales, selectSales } from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const perPageList = [30, 60, 90];
export const sortByList = ["date", "status"];
export enum CheckoutScreenTypes {
  INITIAL = "initial",
  PAYMENT = "payment",
  CLOSED = "closed",
  VOID = "void",
}
export enum SaleStatusConditionTypes {
  PAID_ONLINE_WITH_TIPS = "paidOnlineWithTips",
  PAID_ONLINE_WITHOUT_TIPS = "paidOnlineNoTips",
  REMAINING_BALANCE = "remainingBalance",
  PRE_AUTHORIZED = "preAuthorized",
}
export enum DualPricingPaymentTypes {
  CARD = "card",
  CASH = "cash",
}

export const useSales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const {
    sales,
    meta: { totalCount },
  } = useSelector(selectSales);
  const employees = useSelector(selectEmployees);
  const saleIdParam = query.get("saleId");
  const statusParam = query.get("status");
  const screenParam = query.get("screen");
  const pageParam = query.get("page");
  const perPageParam = query.get("perPage");
  const sortByParam = query.get("sortBy");
  const employeeIdsToFilterParam = query.get("employeeIdsToFilter");
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(perPageParam) || perPageList[0]
  );
  const [sortBy, setSortBy] = useState(sortByParam || sortByList[0]);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saleStatusCondition, setSaleStatusCondition] =
    useState<SaleStatusConditionTypes | null>(null);
  const [employeeIdsToFilter, setEmployeeIdsToFilter] = useState<number[]>(
    employeeIdsToFilterParam?.split(",").map(Number) || []
  );

  const fetchSales = async () => {
    let modifiedSortBy = sortBy;
    if (sortBy === "date") {
      modifiedSortBy = "created_at";
    }

    setLoading(true);
    try {
      const fetchedSales = await dispatch(
        getSales({
          perPage: rowsPerPage,
          sortBy: modifiedSortBy,
          page: Number(pageParam) || 1,
          employeeIdsToFilter: employeeIdsToFilter,
        }) as any
      ).unwrap();
      setLoading(false);
      return fetchedSales.sales;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const selectCorrespondingScreen = (sale?: any) => {
    if (sale?.status === "open") {
      if (
        sale.paidBalanceCents === 0 &&
        sale.paymentTransactions.length > 0 &&
        sale.paymentTransactions[0].preAuth
      ) {
        setSaleStatusCondition(SaleStatusConditionTypes.PRE_AUTHORIZED);
      } else if (
        sale.paidBalanceCents === sale.totalCents &&
        sale.paidTipsCents > 0
      ) {
        setSaleStatusCondition(SaleStatusConditionTypes.PAID_ONLINE_WITH_TIPS);
      } else if (
        sale.paidBalanceCents === sale.totalCents &&
        sale.paidTipsCents === 0 &&
        sale.paidBalanceCents > 0
      ) {
        setSaleStatusCondition(
          SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS
        );
      } else if (
        sale.paidBalanceCents < sale.totalCents &&
        sale.paidBalanceCents > 0
      ) {
        setSaleStatusCondition(SaleStatusConditionTypes.REMAINING_BALANCE);
      } else {
        setSaleStatusCondition(null);
      }
      query.set("screen", CheckoutScreenTypes.INITIAL);
    } else {
      if (sale?.status !== "open") {
        query.set("screen", CheckoutScreenTypes.CLOSED);
      }
    }
  };

  const onSaleRowClick = (sale?: any) => {
    setShouldShowDrawer(true);
    setSaleStatusCondition(null);
    const saleStatus = sale?.status;
    query.set("saleId", sale.id);
    query.set("status", saleStatus);
    selectCorrespondingScreen(sale);

    navigate(`${Path.SALES}?${query.toString()}`);
  };

  const handleClientSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          searchClients(inputValue) as any
        ).unwrap();
        const results =
          response.clients?.map((c: any) => ({
            value: c.id,
            label: c.name,
            phone: c.phone,
            email: c.email,
            createdAt: c.createdAt,
            avatar: c.avatar,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  const onOpenNewCheckoutDrawer = () => {
    onResetDrawerScreens();
    if (!shouldShowDrawer) {
      setShouldShowDrawer(true);
    }
  };

  const onCloseDrawer = () => {
    onResetDrawerScreens();
    setShouldShowDrawer(false);
  };

  const onResetDrawerScreens = () => {
    query.delete("saleId");
    query.delete("status");
    query.delete("screen");
    query.delete("dualPricingPaymentMethod");
    setSaleStatusCondition(null);
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  useEffect(() => {
    // Update URL parameters
    if (employeeIdsToFilter.length > 0) {
      query.set("employeeIdsToFilter", employeeIdsToFilter.join(","));
    } else {
      query.delete("employeeIdsToFilter");
    }

    // Check if employee filter changed to reset page
    if (
      employeeIdsToFilterParam &&
      employeeIdsToFilter.length !==
        employeeIdsToFilterParam.split(",").map(Number).length
    ) {
      query.set("page", "1");
    } else {
      query.set("page", pageParam || "1");
    }

    query.set("perPage", rowsPerPage.toString());
    query.set("sortBy", sortBy);

    // Use replace: true to avoid adding to browser history
    navigate(`${Path.SALES}?${query.toString()}`, { replace: true });
  }, [rowsPerPage, sortBy, pageParam, employeeIdsToFilter]);

  // Separate effect for data fetching that depends on the URL parameters
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchSales();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    query.get("page"),
    query.get("perPage"),
    query.get("sortBy"),
    query.get("employeeIdsToFilter"),
  ]);

  useEffect(() => {
    if (saleIdParam) {
      setShouldShowDrawer(true);
    }
  }, []);

  useEffect(() => {
    selectCorrespondingScreen(find(sales, { id: Number(saleIdParam) }));
  }, [sales]);

  return {
    shouldShowDrawer,
    setShouldShowDrawer,
    onSaleRowClick,
    rowsPerPage,
    setSortBy,
    sortBy,
    setRowsPerPage,
    saleIdParam,
    statusParam,
    screenParam,
    handleClientSearch,
    onOpenNewCheckoutDrawer,
    onResetDrawerScreens,
    loading,
    saleStatusCondition,
    totalCount,
    onCloseDrawer,
    sales,
    setSaleStatusCondition,
    selectCorrespondingScreen,
    employeeIdsToFilter,
    setEmployeeIdsToFilter,
    employees,
  };
};
