import * as React from "react";
import { useClientCsvImportStatus } from "./useClientCsvImportStatus";
import { AsButton } from "app/subframe";
import { Loading } from "app/components/loading/loading";
import { Icon } from "@subframe/core";

interface ClientCsvImportStatusProps {}

export const ClientCsvImportStatus: React.FC<
  ClientCsvImportStatusProps
> = ({}) => {
  const { importStatus, handleRetry, handleBackToClients } =
    useClientCsvImportStatus();

  if (!importStatus) return null;

  const isProcessing = importStatus?.status === "processing";
  const isCompleted = importStatus?.status === "completed";
  const isFailed = importStatus?.status === "failed";

  return (
    <div className="flex items-center justify-center absolute -translate-y-20 inset-0">
      <div className="max-w-md w-full text-center">
        {isProcessing && (
          <>
            <div className="mb-8">
              <Loading type="dark" />
            </div>
            <h2 className="text-xl font-medium mb-2">
              Processing your data <br /> right now
            </h2>
            {/* <div className="w-48 h-1 bg-gray-200 mx-auto rounded-full">
            <div
              className="h-full bg-green-500 rounded-full transition-all duration-500"
              style={{ width: `${importStatus?.progress}%` }}
            />
          </div> */}
          </>
        )}
        {isCompleted && (
          <>
            <div className="mb-8">
              <div className="mx-auto flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                >
                  <path
                    d="M37.7706 48.9874L47.6581 58.8708L65.3956 41.1291M83.3248 49.9958C83.3248 68.4007 68.4047 83.3208 49.9998 83.3208C31.5949 83.3208 16.6748 68.4007 16.6748 49.9958C16.6748 31.5909 31.5949 16.6708 49.9998 16.6708C68.4047 16.6708 83.3248 31.5909 83.3248 49.9958Z"
                    stroke="#039855"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <h2 className="text-2xl font-medium mb-8">
              Your import was successful
            </h2>
            <AsButton
              leadingIcon="FeatherChevronLeft"
              text="Back to client portal"
              variant="default"
              onClick={() => handleBackToClients()}
              className="w-[300px] mx-auto"
              size="lg"
            />
          </>
        )}
        {isFailed && (
          <>
            <div className="mb-8">
              <div className="mx-auto flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                >
                  <path
                    d="M38.4167 37.725L63.625 62.9333M38.4167 62.9333L63.625 37.725M83.65 50.325C83.65 68.7299 68.7299 83.65 50.325 83.65C31.9201 83.65 17 68.7299 17 50.325C17 31.9201 31.9201 17 50.325 17C68.7299 17 83.65 31.9201 83.65 50.325Z"
                    stroke="#D92D20"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <h2 className="text-2xl font-medium mb-8">
              Your import was unsuccessful
            </h2>
            <AsButton
              text="Try again"
              variant="default"
              onClick={() => handleRetry()}
              className="w-[300px] mx-auto mb-4"
              size="lg"
            />
            <AsButton
              text="Or contact us"
              variant="link"
              onClick={() => handleBackToClients()}
              className="w-[300px] mx-auto"
              size="lg"
            />
          </>
        )}
      </div>
    </div>
  );
};
