import { useEffect } from "react";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { Path } from "app/path";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { OnboardingStep, selectOnboarding } from "app/redux/onboardingSlice";
import { generatePath } from "react-router-dom";
import { parameterize } from "app/utils/string";
import { useQuery } from "app/utils/useQuery";
import { selectReseller } from "app/redux/resellerSlice";

const pathsToNotShowNavbar = [
  Path.LOGIN,
  Path.SIGNUP,
  Path.ONBOARDING,
  Path.CLIENT_IMPORT_CSV,
];

const isPathIncluded = (currentPath: string, basePaths: string[]) => {
  return basePaths.some((basePath) => {
    const formattedBasePath = basePath.replace(/\/:[^\/]+/g, ""); // Remove dynamic segments
    return currentPath.startsWith(formattedBasePath);
  });
};

export const useProtectedRoutes = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const reseller = useSelector(selectReseller);
  const { user, logout } = UserAuth() as UserContextType;
  const onboarding = useSelector(selectOnboarding);
  const location = useLocation();
  const shouldShowNavbar = !isPathIncluded(
    location.pathname,
    pathsToNotShowNavbar
  );
  const areScheduleRoutes = [Path.HOME, Path.CLIENTS].includes(
    location.pathname as any
  );
  const nonAdminRoutes = [Path.HOME, Path.USER];

  useEffect(() => {
    if (user?.role !== "admin") {
      if (!nonAdminRoutes.includes(location.pathname as any)) {
        // toast.warn("You must be an admin to access this page");
        return navigate(Path.HOME);
      }
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          query.set("redirectUrl", location.pathname); // Save the current path to redirect after login
          navigate(`${Path.LOGIN}?${query.toString()}`);
        }
        if (user?.archivedAt) {
          navigate(Path.LOGIN);
          await logout();
          toast("This account has been archived");
        }

        if (onboarding.step !== OnboardingStep.COMPLETE) {
          navigate(
            generatePath(Path.ONBOARDING, {
              step: parameterize(onboarding.step),
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user, location.pathname, navigate]);

  return {
    user,
    isReseller: user?.reseller,
    location,
    shouldShowNavbar,
    areScheduleRoutes,
    reseller,
  };
};
