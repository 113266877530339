import classNames from "classnames";
import * as React from "react";

export interface InfoBannerProps {
  text: string;
  className?: string;
}

export const InfoBanner: React.FC<InfoBannerProps> = ({ text, className }) => {
  return (
    <div className={classNames("bg-[#FEFE53] p-2", className)}>
      <p className=" text-neutral-900 font-medium">{text}</p>
    </div>
  );
};
