export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  // Math.floor(Math.log(bytes) / Math.log(k)) determines the appropriate unit
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Converts to the appropriate unit and formats with specified decimals
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
    sizes[i]
  }`;
};
