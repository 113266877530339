import * as React from "react";
import { useAvailabilitiesModal } from "./useAvailabilitiesModal";
import { IntentScreen } from "app/staff/staffHours/components/screens/intentScreen/intentScreen";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { EditScreen } from "../screens/editScreen/editScreen";
import { AsIconButton, DialogLayout } from "app/subframe";

export interface AvailabilitiesModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  availability?: any;
}

export const AvailabilitiesModal: React.FC<AvailabilitiesModalProps> = ({
  isOpen,
  onOpenChange,
  availability,
}) => {
  const {
    screenIndex,
    setScreenIndex,
    availabilityEditingIntent,
    setAvailabilityEditingIntent,
  } = useAvailabilitiesModal({
    isOpen,
  });

  const screens = [
    <IntentScreen
      setScreenIndex={setScreenIndex}
      availability={availability}
      setAvailabilityEditingIntent={setAvailabilityEditingIntent}
      screenIndex={screenIndex}
    />,
    <EditScreen
      availability={availability}
      availabilityEditingIntent={availabilityEditingIntent!}
      // dateToChange={availability?.date}
      onOpenChange={onOpenChange}
    />,
  ];

  return (
    <DialogLayout
      open={isOpen}
      onOpenChange={onOpenChange}
      className="rounded px-8 py-12 z-[1000]"
    >
      <div className="flex relative flex-col h-112 w-144 py-12 justify-center mobile:w-full items-center px-8">
        <AsIconButton
          name="FeatherX"
          variant="ghost"
          size="xs"
          className="absolute top-4 right-4"
          onClick={() => onOpenChange(false)}
        />
        <FadeInSlide direction="bottom" className="w-full">
          {screens[screenIndex]}
        </FadeInSlide>
      </div>
    </DialogLayout>
  );
};
