"use client";

import * as React from "react";
import { DialogLayout } from "app/subframe/layouts/DialogLayout";
import { AsIconButton } from "app/subframe/components/AsIconButton";
import { Switch } from "app/subframe/components/Switch";
import { Avatar } from "app/components/avatar/avatar";
import { AsInput } from "app/subframe/components/AsInput";
import { AsButton } from "app/subframe/components/AsButton";
import useStopRepeatAppointment from "./useStopRepeatAppointment";

interface StopRepeatAppointmentProps {
  isOpen: boolean;
  appointmentGroup: any;
  onOpenChange: (isOpen: boolean) => void;
  onSave?: () => Promise<void>;
}

function StopRepeatAppointment({
  isOpen,
  appointmentGroup,
  onOpenChange,
  onSave,
}: StopRepeatAppointmentProps) {
  const { confirmStopRepeat, appointmentDetails } = useStopRepeatAppointment({
    appointmentGroup,
    onOpenChange,
    onSave,
  });

  const { selectedClient, date, time, selectedServices } = appointmentDetails;

  return (
    <DialogLayout
      open={isOpen}
      onOpenChange={onOpenChange}
      className="z-[1000]"
    >
      <div className="flex h-144 w-192 flex-col items-center gap-4 rounded-lg bg-default-background px-6 pt-6 pb-10 shadow-dialog-shadow mobile:h-144 mobile:w-96 mobile:px-2 mobile:pt-2 mobile:pb-4">
        <div className="flex w-full items-start justify-end gap-6">
          <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
            <span className="text-body-bold font-body-bold text-neutral-900">
              Repeating appointment
            </span>
            <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
              Choose how often the appointment should repeat.
            </span>
          </div>
          <AsIconButton
            className="h-8 w-8 flex-none"
            variant="ghost"
            leadingIcon="FeatherX"
            trailingIcon={null}
            text="Label"
            size="default"
            disabled={false}
            onClick={() => {
              onOpenChange(false);
            }}
          />
        </div>
        <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-50 bg-neutral-0 px-3 py-4">
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-700">
              Repeating appointment
            </span>
            <Switch
              checked={false}
              disabled={true}
              className="cursor-default"
            />
          </div>
          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
          <div className="flex w-full items-start gap-4 mobile:flex-col mobile:flex-nowrap mobile:gap-4">
            <div className="flex grow shrink-0 basis-0 items-start gap-4">
              <Avatar
                src={selectedClient?.avatar}
                name={selectedClient?.name}
                size={40}
                type="character"
              />
              <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
                <div className="flex flex-col items-start justify-center gap-2">
                  <span className="text-body-bold font-body-bold text-neutral-700">
                    {selectedClient?.name}
                  </span>
                </div>
                <div className="flex items-start gap-2">
                  <div className="flex items-center gap-1">
                    <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                      on
                    </span>
                    <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-700">
                      {date}
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                      at
                    </span>
                    <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-700">
                      {time}
                    </span>
                  </div>
                </div>
                {selectedServices.map((service: any, index: number) => (
                  <div key={index} className="flex items-start gap-2 flex-wrap">
                    <span className="font-['Inter'] font-[600] text-neutral-700">
                      {service.title}
                    </span>
                    <div className="flex items-center gap-1">
                      <span className="font-['Inter'] font-[400] text-neutral-500">
                        with
                      </span>
                      <span className="font-['Inter'] font-[600] text-neutral-700">
                        {service?.employee?.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
                Every
              </span>
              <AsInput
                className="h-12 w-32 flex-none"
                leading={appointmentGroup?.appointmentRecurrence?.interval || 1}
                error={false}
                showLeadingIcon={false}
                disabled={true}
                trailing=""
              />
              <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
                Week(s)
              </span>
            </div>
          </div>
          <span className="w-full font-['Inter'] text-[14px] font-[500] leading-[20px] text-error-600">
            Please note: this will remove all future occurrence of the repeating
            appointment
          </span>
        </div>
        <div className="flex flex-col items-center gap-10" />
        <div className="flex w-full max-w-[468px] grow shrink-0 basis-0 items-end justify-center gap-4">
          <AsButton
            className="w-full"
            variant="ghost"
            leadingIcon={null}
            trailingIcon={null}
            text="Cancel"
            size="md"
            loader={false}
            disabled={false}
            shadow={false}
            onClick={() => {
              onOpenChange(false);
            }}
          />
          <AsButton
            className="w-full"
            variant="default"
            leadingIcon={null}
            trailingIcon={null}
            text="Save"
            size="md"
            loader={false}
            disabled={false}
            shadow={false}
            onClick={confirmStopRepeat}
          />
        </div>
      </div>
    </DialogLayout>
  );
}

export default StopRepeatAppointment;
