export enum Path {
  HOME = "/",
  SIGNUP = "/signup",
  LOGIN = "/login",
  SETTINGS = "/settings",
  BUSINESS_SERVICES = "/business-services",
  ONBOARDING = "/onboarding/:step",
  STAFF = "/staff",
  SALES = "/sales",
  CLIENTS = "/clients",
  CLIENT_IMPORT_CSV = "/clients/import-csv",
  CLIENT_IMPORT_CSV_STATUS = "/clients/import-csv/status",
  REPORTS = "/reports",
  ACCEPT_INVITE = "/accept-invite",
  USER = "/user",
  CONFIRM_APPOINTMENT = "/confirm-appointment/:appointmentGroupId",
  RESELLER_HOME = "/reseller-home",
  BILLING = "/billing",
}
