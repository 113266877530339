import * as React from "react";
import { useCrispChat } from "./useCrispChat";

export interface CrispChatProps {}

export const CrispChat: React.FC<CrispChatProps> = () => {
  const {} = useCrispChat();

  // Component doesn't render anything visible
  return <></>;
};
