import { LocalStorageKeys } from "app/dictionaries/localstorage";
import {
  SubscriptionPlansType,
  SubscriptionStatusType,
} from "app/dictionaries/plans";
import { Path } from "app/path";
import { getResellerAccounts, selectReseller } from "app/redux/resellerSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const perPageList = [30, 60, 90];
export const sortByList = [
  { title: "Recent", value: "updated_at" },
  { title: "Business name", value: "name" },
  { title: "Email", value: "email" },
];

export const getAccountStatusInfo = (account: any) => {
  if (
    !account.subscription ||
    account.subscription.plan === SubscriptionPlansType.TRIAL ||
    account.subscription.status === SubscriptionStatusType.INACTIVE
  ) {
    return {
      active: false,
      badgeType: "warning",
      description: "Inactive - Require payment",
      accountName: account.name,
    };
  }

  if (
    !account.acceptBlueApiKey ||
    !account.acceptBlueApiPin ||
    !account.acceptBlueTokenizationApi
  ) {
    return {
      active: false,
      badgeType: "warning",
      description: "Inactive  - Require API info",
      accountName: account.name,
    };
  }

  return {
    active: true,
    badgeType: "success",
    description: "Active - Paying",
    accountName: account.name,
  };
};

export const useHome = () => {
  const dispatch = useDispatch();
  const {
    name,
    affiliateReferralId,
    accounts,
    meta: { totalCount },
  } = useSelector(selectReseller);
  const navigate = useNavigate();
  const query = useQuery();
  const [rowsPerPage, setRowsPerPage] = useState(perPageList[0]);
  const [loading, setLoading] = React.useState(false);
  const [impersonating, setImpersonating] = React.useState(false);
  const [showCreateMerchantModal, setShowCreateMerchantModal] = useState(false);
  const [bannerDescription, setBannerDescription] = useState("");

  const fetchResellerAccounts = async () => {
    setLoading(true);
    try {
      const data = await dispatch(getResellerAccounts() as any).unwrap();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onAccountImpersonate = (id: number) => {
    setImpersonating(true);
    localStorage.setItem(LocalStorageKeys.IMPERSONATION_ID, id.toString());

    const account = find(accounts, { id }) as any;

    const isInactiveAccount =
      !account.subscription ||
      account.subscription.plan === SubscriptionPlansType.TRIAL ||
      account.subscription.status === SubscriptionStatusType.INACTIVE;

    const hasMissingApiInfo =
      !account?.acceptBlueApiKey ||
      !account?.acceptBlueApiPin ||
      !account?.acceptBlueTokenizationApi;

    if (isInactiveAccount) {
      window.location.href = `${Path.BILLING}?path=billing`;
    } else if (hasMissingApiInfo) {
      window.location.href = `${Path.SETTINGS}?path=apiKeys`;
    } else {
      window.location.href = Path.HOME;
    }
    setImpersonating(false);
  };

  const bannerInit = () => {
    const accountsStatuses = accounts.map((account: any) => {
      return getAccountStatusInfo(account);
    });

    const inactiveAccounts = accountsStatuses.filter(
      (status: any) => !status.active
    );

    if (inactiveAccounts.length === 0) {
      return;
    }

    if (inactiveAccounts.length === 1) {
      setBannerDescription(
        `${inactiveAccounts[0].accountName} is inactive at the moment and requires your attention.`
      );
    } else {
      setBannerDescription(
        `${inactiveAccounts.length} businesses are inactive at the moment and requires your attention.`
      );
    }
  };

  useEffect(() => {
    fetchResellerAccounts();
  }, []);

  useEffect(() => {
    bannerInit();
  }, [accounts]);

  useEffect(() => {
    const currentAffValue = query.get("aff");

    // Check if we need to add/update the aff parameter
    const shouldUpdateAff =
      // We have an affiliate ID to use
      affiliateReferralId &&
      affiliateReferralId !== "" &&
      // No aff parameter exists
      (!currentAffValue ||
        // Or aff parameter is "undefined"
        currentAffValue === "undefined" ||
        // Or aff parameter is "null"
        currentAffValue === "null");

    if (shouldUpdateAff) {
      // Set the aff parameter to the affiliate referral ID
      query.set("aff", affiliateReferralId);
      navigate(`${Path.RESELLER_HOME}?${query.toString()}`);
    }
  }, [query.get("aff"), affiliateReferralId]);

  return {
    loading,
    accounts,
    totalCount,
    rowsPerPage,
    setRowsPerPage,
    onAccountImpersonate,
    impersonating,
    showCreateMerchantModal,
    setShowCreateMerchantModal,
    resellerName: name,
    bannerDescription,
    fetchResellerAccounts,
  };
};
