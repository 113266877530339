import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";
import * as React from "react";
import { useHome } from "./useHome";
import { Loading } from "app/components/loading/loading";
import { ResellerAccountsTable } from "./components/resellerAccountsTable/resellerAccountsTable";
import { AsButton } from "app/subframe";
import { CreateMerchantModal } from "./components/createMerchantModal/createMerchantModal";
import { titleize } from "app/utils/string";
import { truncate } from "lodash";
import { InfoBanner } from "./components/infoBanner/infoBanner";
export interface HomeProps {}

export const Home: React.FC<HomeProps> = (props) => {
  const {} = props;
  const {
    loading,
    totalCount,
    rowsPerPage,
    setRowsPerPage,
    onAccountImpersonate,
    impersonating,
    showCreateMerchantModal,
    setShowCreateMerchantModal,
    resellerName,
    bannerDescription,
    fetchResellerAccounts,
  } = useHome();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (impersonating) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <>
      <CreateMerchantModal
        isOpen={showCreateMerchantModal}
        onOpenChange={setShowCreateMerchantModal}
        fetchResellerAccounts={fetchResellerAccounts}
      />
      <div
        className={classNames(
          "flex flex-col relative w-full p-4 pt-0 gap-4 mobile:p-2 mobile:gap-2",
          {
            "h-[85dvh]": isBelowSm,
          }
        )}
      >
        {bannerDescription && (
          <InfoBanner
            text={bannerDescription}
            className={"absolute top-0 left-0 w-full text-center"}
          />
        )}
        <div className="flex ml-2 justify-between pt-14 mobile:pt-20">
          <div className="flex flex-col gap-4">
            <p className="!font-medium text-3xl text-neutral-700">
              {truncate(titleize(resellerName), {
                length: 20,
              })}{" "}
              MIDS
            </p>
            <p className="text-sm font-[400] text-neutral-500">
              {totalCount} merchants
            </p>
          </div>
          <AsButton
            variant="secondary"
            text="Add a new merchant"
            leadingIcon={"FeatherUserPlus"}
            size="default"
            onClick={() => setShowCreateMerchantModal(true)}
          />
        </div>
        <ResellerAccountsTable
          rowsPerPage={rowsPerPage}
          onAccountImpersonate={onAccountImpersonate}
        />
      </div>
    </>
  );
};
