import * as React from "react";
import { CreditCardOptions, useCcCheckout } from "./useCcCheckout";
import { PrepaidCcCheckout } from "./prepaidCcCheckout/prepaidCcCheckout";
import { CcCheckoutOptions } from "./ccCheckoutOptions";
import { InAppCcCheckout } from "./inAppCcCheckout/inAppCcCheckout";
import { PaymentLinkCcCheckout } from "./paymentLinkCcCheckout/paymentLinkCcCheckout";
import QRCode from "react-qr-code";
import { Loading } from "app/components/loading/loading";
import { AsIconButton, DialogLayout, PaymentModalHeader } from "app/subframe";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { SaleStatusConditionTypes } from "app/sales/useSales";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface CcCheckoutProps {
  initialTotalCents: number;
  recordPaidPayment: (event: any) => void;
  setPayWithMode: (mode: any) => void;
  onPostPaymentRecord: (sale: any) => void;
  total: string;
  tipsCents: number;
  totalCents: number;
  saleStatusCondition: SaleStatusConditionTypes | null;
  onCloseDrawer: () => void;
  isRecordingPayment: boolean;
  incomingCreditCardOption: CreditCardOptions | null;
}

export const CcCheckout: React.FC<CcCheckoutProps> = ({
  incomingCreditCardOption,
  recordPaidPayment,
  setPayWithMode,
  initialTotalCents,
  total,
  tipsCents,
  totalCents,
  onPostPaymentRecord,
  saleStatusCondition,
  onCloseDrawer,
  isRecordingPayment,
}) => {
  const {
    account,
    creditCardOption,
    setCreditCardOption,
    showQrCodeModal,
    paymentLink,
    isCreatingPaymentLink,
    setShowQrCodeModal,
    sale,
  } = useCcCheckout({
    incomingCreditCardOption,
  });

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (!paymentLink || isCreatingPaymentLink) {
    return (
      <div className="flex w-full justify-center py-8">
        <Loading label={"Just a moment"} type="dark" />
      </div>
    );
  }

  const paymentOption = () => {
    switch (creditCardOption) {
      case CreditCardOptions.PREPAID:
        return (
          <PrepaidCcCheckout
            initialTotalCents={initialTotalCents}
            recordPaidPayment={recordPaidPayment}
            setCreditCardOption={setCreditCardOption}
            isRecordingPayment={isRecordingPayment}
            account={account}
            setPayWithMode={setPayWithMode}
          />
        );
      case CreditCardOptions.IN_APP:
        return (
          <InAppCcCheckout
            total={total}
            tipsCents={tipsCents}
            totalCents={totalCents}
            setCreditCardOption={setCreditCardOption}
            onPostPaymentRecord={onPostPaymentRecord}
          />
        );
      case CreditCardOptions.PAYMENT_LINK:
        return (
          <PaymentLinkCcCheckout
            setCreditCardOption={setCreditCardOption}
            paymentLink={paymentLink}
            sale={sale}
          />
        );
      default:
        return (
          <CcCheckoutOptions
            setCreditCardOption={setCreditCardOption}
            setPayWithMode={setPayWithMode}
            setShowQrCodeModal={setShowQrCodeModal}
            saleStatusCondition={saleStatusCondition}
            onCloseDrawer={onCloseDrawer}
          />
        );
    }
  };

  return (
    <>
      <FadeInSlide direction="bottom" className="absolute">
        <DialogLayout
          open={showQrCodeModal}
          onOpenChange={setShowQrCodeModal}
          className="z-[1000]"
        >
          <div className="flex h-144 w-192 flex-col items-center gap-4 rounded-lg bg-default-background px-6 py-6 shadow-dialog-shadow mobile:fixed mobile:rounded-b-none mobile:bottom-0 mobile:left-0 mobile:w-full mobile:h-[90dvh]">
            {isBelowSm ? (
              <PaymentModalHeader
                text="Pay using QR code"
                buttonProps={{
                  onClick: () => setShowQrCodeModal(false),
                }}
              />
            ) : (
              <div className="flex w-full items-center justify-end gap-6">
                <AsIconButton
                  className="h-8 w-8 flex-none"
                  variant="ghost"
                  leadingIcon="FeatherX"
                  trailingIcon={null}
                  text="Label"
                  size="default"
                  onClick={() => {
                    setShowQrCodeModal(false);
                    setCreditCardOption(null);
                  }}
                />
              </div>
            )}
            <div className="flex flex-col items-center gap-10 mobile:gap-5 mobile:mt-20">
              <div className="flex flex-col items-center gap-2">
                {isAboveSm && (
                  <span className="text-heading-1 font-heading-1 text-neutral-900">
                    Pay using QR code
                  </span>
                )}
                <span className="text-heading-2 font-heading-2 text-default-font mobile:text-base">
                  Scan the QR code to get the payment link
                </span>
              </div>
              <div className="flex h-72 w-72 flex-none flex-col items-center gap-4 rounded-lg border-4 border-solid border-success-300 px-6 py-6">
                <QRCode value={paymentLink?.link} className="w-full h-full" />
              </div>
            </div>
          </div>
        </DialogLayout>
      </FadeInSlide>
      {paymentOption()}
    </>
  );
};
