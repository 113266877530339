import { format } from "date-fns";
import * as React from "react";
import { useCreateAppointmentScreen } from "./useCreateAppointmentScreen";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { adjustDateToUTC } from "app/utils/formatTime";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import { formatAsUTC } from "app/utils/formatDate";
import { Avatar } from "app/components/avatar/avatar";
import {
  AsButton,
  AsIconButton,
  AsInput,
  reactSelectStyles,
} from "app/subframe";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { CrudAppointmentActions } from "app/schedules/useSchedules";

export interface CreateAppointmentScreenProps {
  pendingAppointment: any;
  setPendingAppointment: (pendingAppointment: any) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
  setAppointmentDrawer: (drawer: any) => void;
  closeDrawer: () => void;
}

export const CreateAppointmentScreen: React.FC<
  CreateAppointmentScreenProps
> = ({
  pendingAppointment,
  setPendingAppointment,
  toggleAppointmentDrawer,
  getSchedulesOfEmployees,
  setAppointmentDrawer,
  closeDrawer,
}) => {
  const {
    employee,
    employees,
    clients,
    isDateInputFocused,
    setIsDateInputFocused,
    onSubmit,
    onHandleClientChange,
    selectedClient,
    setSelectedClient,
    selectedServices,
    onHandleAddService,
    selectedDate,
    setSelectedDate,
    availableServices,
    serviceSelectValue,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    handleEmployeeChange,
    employeeSelectRef,
    onRemoveService,
    handleClientSearch,
    onDateChange,
    onTimeChange,
  } = useCreateAppointmentScreen({
    pendingAppointment,
    setPendingAppointment,
    toggleAppointmentDrawer,
    getSchedulesOfEmployees,
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-2 mt-1.5 mb-7 w-full items-center justify-between">
        <p className="text-body-bold font-body-bold text-neutral-900">
          Create Appointment for
        </p>
        <div className="flex items-center justify-between">
          <Dropdown
            classNames={{
              content: "rounded-sm",
            }}
          >
            <DropdownTrigger>
              <AsIconButton
                size="xs"
                leadingIcon="FeatherMoreHorizontal"
                variant="ghost"
              />
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem
                variant="flat"
                className="rounded-sm !hover:bg-millet-500 !hover:font-semibold data-[hover=true]:!bg-millet-500"
                onClick={() =>
                  setAppointmentDrawer({
                    isOpen: true,
                    type: CrudAppointmentActions.BLOCKED,
                  })
                }
              >
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="#8D8D95" />
                  </svg>
                  Make Unavailable
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <AsIconButton
            size="xs"
            variant="ghost"
            className="rounded"
            leadingIcon={"FeatherX"}
            onClick={() => closeDrawer()}
          />
        </div>
      </div>
      <form
        className="flex flex-col relative h-[80dvh] mobile:mt-4 overflow-auto"
        onSubmit={onSubmit}
      >
        <div className="flex items-center gap-2.5 mb-6">
          <AsInput
            aria-label="Date Input"
            key={isDateInputFocused ? "is-focused-date" : "is-not-focused-date"}
            name="date"
            textPosition="left"
            leading="On"
            defaultValue={
              isDateInputFocused
                ? formatAsUTC(new Date(selectedDate), "yyyy-MM-dd")
                : formatAsUTC(new Date(selectedDate), "EEE, MMM d")
            }
            type={isDateInputFocused ? "date" : "string"}
            containerClassName="w-full"
            onFocus={() => setIsDateInputFocused(true)}
            onChange={(e) => onDateChange(new Date(e.target.value))}
            onBlur={(e: any) => {
              const dateValue = e.target.value;
              if (dateValue) {
                const adjustedDate = adjustDateToUTC(dateValue);
                setSelectedDate(adjustedDate.toISOString());
              }
              setIsDateInputFocused(false);
            }}
          />
          <AsInput
            key={pendingAppointment?.start}
            aria-label="Start time Input"
            defaultValue={
              pendingAppointment
                ? format(pendingAppointment?.start, "HH:mm")
                : "09:00"
            }
            onChange={(e) => onTimeChange(e.target.value)}
            type="time"
            name="startTime"
            textPosition="left"
            leading={"Start at"}
            containerClassName="w-full"
            className="text-sm text-zinc-500 font-semibold border-zinc-100 bg-transparent"
          />
        </div>
        <div className="flex flex-col gap-6 overflow-auto pb-20 h-[100dvh]">
          {selectedClient ? (
            <div className="flex flex-col relative">
              <div className="flex justify-between pb-4">
                <div className="flex items-center">
                  <Avatar
                    src={selectedClient.avatar}
                    name={selectedClient.name}
                    type="character"
                    size={56}
                  />
                  <div className="flex flex-col justify-between ml-4">
                    <p className="font-semibold pt-0.5">
                      {selectedClient.name}
                    </p>
                    <p className="text-zinc-500 text-sm">
                      Client since{" "}
                      {format(selectedClient.createdAt, "M.d.yyyy")}
                    </p>
                  </div>
                </div>
                <AsIconButton
                  onClick={() => setSelectedClient(null)}
                  variant="ghost"
                  className="rounded"
                  leadingIcon={"FeatherPen"}
                  size="xs"
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                  <p className="font-semibold ">
                    {selectedClient.phone || "(###) ###-####"}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                  <p className="font-semibold ">
                    {selectedClient.email || "###"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <AsyncSelect
              cacheOptions
              loadOptions={handleClientSearch as any}
              onChange={onHandleClientChange}
              className="text-zinc-500 text-sm mobile:text-medium"
              placeholder="Search or select a client"
              components={{
                Option: CustomClientOption,
              }}
              defaultOptions={clients.map((c) => ({
                value: c.id,
                label: c.name,
                phone: c.phone,
                email: c.email,
                createdAt: c.createdAt,
                avatar: c.avatar,
              }))}
              // Optionally, you can add a value prop if needed
              styles={reactSelectStyles({
                borderContainer: true,
              })}
            />
          )}
          {selectedServices &&
            selectedServices.map((service: any, index: any) => (
              <div
                className="flex relative items-end justify-between"
                key={index}
              >
                <div className="flex flex-col gap-3">
                  <p className="text-body-bold font-body-bold text-neutral-900">
                    {service.title}
                  </p>
                  <div className="flex gap-4">
                    <div className="flex gap-1 text-sm">
                      <span className="text-zinc-500 text-sm">with</span>
                      <div
                        className="font-semibold text-zinc-900 cursor-pointer relative hover:underline"
                        onClick={() => setEditingEmployeeInServiceIndex(index)}
                      >
                        {editingEmployeeInServiceIndex === index ? (
                          <div
                            ref={employeeSelectRef}
                            className="absolute -top-2 w-[200px]"
                          >
                            <Select
                              placeholder="Select an employee"
                              components={{
                                Option: CustomEmployeeOption,
                              }}
                              onChange={(selectedOption) =>
                                handleEmployeeChange(index, selectedOption)
                              }
                              className="text-zinc-500 text-sm mobile:text-medium"
                              options={employees.map((emp) => ({
                                value: emp.id,
                                label: emp.name,
                                services: emp.services,
                                selectedDate,
                                availabilities: emp.availabilities,
                                businessServiceId:
                                  selectedServices[index]?.businessService.id, // Pass the specific business service ID
                              }))}
                              onBlur={() =>
                                setEditingEmployeeInServiceIndex(null)
                              }
                              value={
                                selectedServices[index]?.employee
                                  ? {
                                      value:
                                        selectedServices[index]?.employee?.id,
                                      label:
                                        selectedServices[index]?.employee?.name,
                                    }
                                  : null
                              }
                              menuIsOpen={
                                editingEmployeeInServiceIndex === index
                              }
                              styles={reactSelectStyles({})}
                            />
                          </div>
                        ) : (
                          service?.employee?.name
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-body-bold font-body-bold text-neutral-900">
                    {service.price}
                  </p>
                  <AsIconButton
                    onClick={() => onRemoveService(index)}
                    variant="ghost"
                    className="absolute top-0 right-0"
                    leadingIcon={"FeatherTrash2"}
                    size="xs"
                  />
                </div>
              </div>
            ))}
          <Select
            placeholder="Search or select a service"
            components={{
              Option: CustomServiceOption,
            }}
            onChange={onHandleAddService}
            value={serviceSelectValue}
            className="text-zinc-500 text-sm mobile:text-medium"
            options={availableServices?.map((category) => ({
              label: category.name,
              options: category.services.map((service: any) => ({
                value: service.id,
                label: service.title,
                price: service.price,
                status: service.status,
                employee,
              })),
            }))}
            styles={reactSelectStyles({
              borderContainer: true,
            })}
          />
        </div>
        <div className="flex flex-col w-full bottom-0 left-0 p-4 pb-7 fixed bg-white">
          <AsButton
            type="submit"
            size="md"
            text="Create appointment"
            disabled={!selectedClient || selectedServices.length === 0}
          />
        </div>
      </form>
    </div>
  );
};
