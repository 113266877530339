import { AvailabilityTypes } from "app/redux/availabilitiesSlice";
import { AsButton } from "app/subframe";
import { format, parseISO } from "date-fns";
import * as React from "react";

export interface IntentScreenProps {
  setScreenIndex: (index: number) => void;
  availability?: any;
  setAvailabilityEditingIntent: (intent: AvailabilityTypes) => void;
  screenIndex: number;
}

export const IntentScreen: React.FC<IntentScreenProps> = ({
  setScreenIndex,
  availability,
  setAvailabilityEditingIntent,
  screenIndex,
}) => {
  const handleIntentClick = (intent: AvailabilityTypes) => {
    setAvailabilityEditingIntent(intent);
    setScreenIndex(screenIndex + 1);
  };

  return (
    <>
      <div className="flex justify-center mb-5 w-full">
        <p className="text-body-bold font-body-bold text-neutral-900">
          What would you like to edit?
        </p>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <AsButton
          className="h-24 w-full rounded"
          variant="secondary"
          onClick={() =>
            handleIntentClick(AvailabilityTypes.WEEKLY_AVAILABILITY)
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-body-bold font-body-bold text-neutral-900">
              Repeating Shifts
            </p>
            <p className="text-zinc-500">All {availability?.day}s</p>
          </div>
        </AsButton>
        <AsButton
          className="h-24 w-full rounded"
          variant="secondary"
          onClick={() =>
            handleIntentClick(AvailabilityTypes.AVAILABILITY_OVERRIDE)
          }
        >
          <div className="flex flex-col gap-1">
            <p className="text-body-bold font-body-bold text-neutral-900">
              This day only
            </p>
            <p className="text-zinc-500">
              {format(parseISO(availability?.date), "MMM d, yyyy")}
            </p>
          </div>
        </AsButton>
      </div>
    </>
  );
};
