import * as React from "react";
import { useBilling } from "./useBilling";
import { Loading } from "app/components/loading/loading";
import { SubscriptionPlansType } from "app/dictionaries/plans";

export interface BillingProps {}

export const Billing: React.FC<BillingProps> = ({}) => {
  const { account, clientSecret, loading } = useBilling();

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <div className="w-full h-full pt-20 mobile:pt-10 mobile:pb-32">
      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        customer-session-client-secret={clientSecret}
      ></stripe-pricing-table>
    </div>
  );
};
