import { Path } from "app/path";
import { AsButton } from "app/subframe";
import classNames from "classnames";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export enum UpgradeBannerType {
  BANNER = "banner",
  BLOCK = "block",
}

export interface UpgradeBannerProps {
  text: string;
  type?: UpgradeBannerType;
  ctaLink?: string;
  ctaText?: string;
}

export const UpgradeBanner: React.FC<UpgradeBannerProps> = ({
  text,
  type = UpgradeBannerType.BANNER,
  ctaLink = Path.BILLING,
  ctaText = "Upgrade",
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        "justify-between w-full h-full p-2 bg-success-50 rounded",
        {
          "flex items-center": type === UpgradeBannerType.BANNER,
          "flex flex-col gap-4": type === UpgradeBannerType.BLOCK,
        }
      )}
    >
      <p className="text-sm font-medium text-neutral-700">{text}</p>
      <AsButton
        text={ctaText}
        variant="secondary"
        size="sm"
        onClick={() => navigate(ctaLink)}
      />
    </div>
  );
};
