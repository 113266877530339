import {
  Button,
  Divider,
  ModalBody,
  ModalHeader,
  Switch,
} from "@nextui-org/react";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import {
  AvailabilityTypes,
  AvailabilityStatusTypes,
} from "app/redux/availabilitiesSlice";
import { format24TimeTo12Hour } from "app/utils/formatTime";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useEditScreen } from "./useEditScreen";

export interface EditScreenProps {
  availability: any;
  availabilityEditingIntent: AvailabilityTypes;
  onOpenChange(isOpen: boolean): void;
}

export const EditScreen: React.FC<EditScreenProps> = ({
  availability,
  availabilityEditingIntent,
  onOpenChange,
}) => {
  const { scheduled, onScheduledSwitchToggle, handleAvailabilitySubmit } =
    useEditScreen({
      availability,
      availabilityEditingIntent,
      onOpenChange,
    });

  if (!availability) {
    return null;
  }

  return (
    <>
      <div className="flex justify-center mb-5">
        <div className="flex flex-col items-center gap-5 w-full">
          <p className="text-body-bold font-body-bold text-neutral-900">
            {availabilityEditingIntent === AvailabilityTypes.WEEKLY_AVAILABILITY
              ? `Editing ${availability.day} availabilities`
              : `Editing availability for ${format(
                  parseISO(availability.date),
                  "MMM d, yyyy"
                )}`}
          </p>
          <p className="text-small text-zinc-500">
            {availabilityEditingIntent === AvailabilityTypes.WEEKLY_AVAILABILITY
              ? `Changes affect all ${availability.day}s`
              : "Changes only affect this day"}
          </p>
          <Divider />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center mb-4">
          <Switch
            color="success"
            size="sm"
            defaultSelected={scheduled}
            onChange={(event) => onScheduledSwitchToggle(event.target.checked)}
            aria-label="Service status"
          />
          <p className="text-zinc-500 text-sm">Scheduled</p>
        </div>
        <div
          className={classNames("flex flex-col gap-2", {
            hidden: !scheduled,
          })}
        >
          <p className="text-zinc-500 text-sm">Start Time</p>
          <InputEdit
            type="time"
            name="timeStart"
            defaultValue={availability.timeStart}
            valueClassName="text-body-bold font-body-bold text-neutral-900"
            valueShownWhenNotEditing={format24TimeTo12Hour(
              availability.timeStart
            )}
            onSave={(value) => handleAvailabilitySubmit({ ...value })}
          />
        </div>
        <div
          className={classNames("flex flex-col gap-2", {
            hidden: !scheduled,
          })}
        >
          <p className="text-zinc-500 text-sm">End Time</p>
          <InputEdit
            type="time"
            name="timeEnd"
            defaultValue={availability.timeEnd}
            valueClassName="text-body-bold font-body-bold text-neutral-900"
            valueShownWhenNotEditing={format24TimeTo12Hour(
              availability.timeEnd
            )}
            onSave={(value) =>
              handleAvailabilitySubmit({ ...availability, ...value })
            }
          />
        </div>
      </div>
    </>
  );
};
