export enum SubscriptionPlansType {
  TRIAL = "trial",
  ESSENTIALS_MONTHLY = "essentials-monthly",
  ESSENTIALS_YEARLY = "essentials-yearly",
  PRO_MONTHLY = "pro-monthly",
  PRO_YEARLY = "pro-yearly",
}

export enum SubscriptionTierType {
  TRIAL = "trial",
  ESSENTIALS = "essentials",
  PRO = "pro",
}

export const subscriptionTier = {
  [SubscriptionPlansType.TRIAL]: SubscriptionTierType.TRIAL,
  [SubscriptionPlansType.ESSENTIALS_MONTHLY]: SubscriptionTierType.ESSENTIALS,
  [SubscriptionPlansType.ESSENTIALS_YEARLY]: SubscriptionTierType.ESSENTIALS,
  [SubscriptionPlansType.PRO_MONTHLY]: SubscriptionTierType.PRO,
  [SubscriptionPlansType.PRO_YEARLY]: SubscriptionTierType.PRO,
};

export enum SubscriptionStatusType {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
