import { SubscriptionPlansType } from "app/dictionaries/plans";
import {
  getStripeCustomerClientSecret,
  getStripeCustomerPortal,
  selectAccount,
} from "app/redux/accountSlice";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBilling = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const init = async () => {
    setLoading(true);
    try {
      if (account?.subscription?.plan !== SubscriptionPlansType.TRIAL) {
        const { url } = await dispatch(
          getStripeCustomerPortal() as any
        ).unwrap();
        window.location.href = url;
      } else {
        const { secret } = await dispatch(
          getStripeCustomerClientSecret() as any
        ).unwrap();
        setClientSecret(secret);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    account,
    clientSecret,
    loading,
  };
};
