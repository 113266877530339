import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { selectUser } from "app/redux/userSlice";

export enum SettingTypes {
  BUSINESS_DETAILS = "businessDetails",
  LOCATION = "location",
  BRANDING = "branding",
  BUSINESS_POLICIES = "businessPolicies",
  API_KEYS = "apiKeys",
}

export const useSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const path = query.get("path");
  const newlySubscribedPlan = query.get("stripe-subscription-successful-plan");
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const { logout } = UserAuth() as UserContextType;
  const [showSubscriptionSuccessModal, setShowSubscriptionSuccessModal] =
    useState(false);

  const onMenuPress = (key: string) => {
    navigate(`?path=${key}`);
  };

  const refreshAccount = async () => {
    try {
      await dispatch(getAccount() as any);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!path) {
      navigate(`?path=${SettingTypes.BUSINESS_DETAILS}`);
    }
  }, [path]);

  useEffect(() => {
    if (newlySubscribedPlan) {
      setShowSubscriptionSuccessModal(true);
      if (!account.id) {
        refreshAccount();
      }
    }
  }, []);

  return {
    onMenuPress,
    path,
    logout,
    account,
    user,
    showSubscriptionSuccessModal,
    setShowSubscriptionSuccessModal,
    newlySubscribedPlan,
  };
};
