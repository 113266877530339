"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Update and guides — https://app.subframe.com/feb895689ba0/library?component=Update+and+guides_c4404d88-11b8-419b-aae6-6c4551360bb5
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { Link } from "react-router-dom";

interface UpdateAndGuidesRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default";
  collapsed?: boolean;
  className?: string;
}

const UpdateAndGuidesRoot = React.forwardRef<
  HTMLElement,
  UpdateAndGuidesRootProps
>(function UpdateAndGuidesRoot(
  {
    variant = "default",
    collapsed = false,
    className,
    ...otherProps
  }: UpdateAndGuidesRootProps,
  ref
) {
  return (
    <Link
      to={"https://guides.heyallset.com/Start_here/Creating-account"}
      target="_blank"
    >
      <div
        className={SubframeCore.twClassNames(
          "group/c4404d88 flex h-10 w-56 cursor-pointer items-center justify-center gap-4 rounded border border-solid border-green-500 bg-[#fefe5333] px-2 py-2 hover:h-10 hover:items-center hover:justify-center hover:bg-[#fefe5380] active:border active:border-solid active:border-neutral-900 active:bg-[#fefe53cc]",
          {
            "h-10 w-10 items-center justify-center hover:items-center hover:justify-center":
              collapsed,
          },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div
          className={SubframeCore.twClassNames(
            "flex shrink-0 basis-0 items-center gap-2",
            {
              "h-auto w-auto flex-none": collapsed,
              "ml-[3px] grow": !collapsed,
            }
          )}
        >
          <SubframeCore.Icon
            className="text-body font-body text-green-500"
            name="FeatherBookOpen"
          />
          <span
            className={SubframeCore.twClassNames(
              "font-['Inter'] text-[14px] font-[500] w-full leading-[24px] text-neutral-900",
              { hidden: collapsed }
            )}
          >
            Guides &amp; updates
          </span>
        </div>
      </div>
    </Link>
  );
});

export const UpdateAndGuides = UpdateAndGuidesRoot;
