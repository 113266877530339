import React, { useEffect, useRef } from "react";
import { format } from "date-fns";
import Select from "react-select";
import { useCustomToolbar } from "./useCustomToolbar";
import { AsButton, AsIconButton, reactSelectStyles } from "app/subframe";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import { Icon } from "@subframe/core";

export const CustomToolbar: React.FC<any> = ({
  date,
  onNavigate,
  showPicker,
  setShowPicker,
  onCustomNavigate,
}) => {
  const { onShopsChange, goToBack, goToNext, goToToday, shops, shop } =
    useCustomToolbar({
      onNavigate,
    });
  const defaultClassNames = getDefaultClassNames();
  const containerRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <div className="flex items-center relative justify-center bg-white">
      <div className="flex items-center !absolute left-4 z-[100]">
        <Select
          key={`shop-select-${shop?.id}`}
          className="w-52 text-sm"
          options={shops.map((shop) => ({
            value: shop.id,
            label: shop.name,
            complete: shop?.latitude && shop?.longitude && shop.addressLine1,
          }))}
          onChange={onShopsChange}
          placeholder="Select Shop"
          defaultValue={{
            value: shop?.id,
            label: shop?.name,
          }}
          styles={reactSelectStyles({
            borderContainer: true,
          })}
          isOptionDisabled={(option: any) => !option.complete}
        />
      </div>
      <div
        className="flex self-center relative justify-between py-1 my-3 items-center bg-white w-full max-w-[320px] border-gray-300"
        ref={containerRef}
      >
        <AsIconButton
          variant="ghost"
          onClick={goToBack}
          size="sm"
          className="text-gray-600"
          leadingIcon="FeatherChevronLeft"
        />
        <AsButton
          variant="ghost"
          size="sm"
          onClick={() => setShowPicker(!showPicker)}
          className="text-zinc-700 font-semibold"
          leadingIcon={"FeatherCalendar"}
          text={format(date, "EEE, MMM dd")}
        />
        <AsIconButton
          variant="ghost"
          onClick={goToNext}
          size="xs"
          className="text-gray-600"
          leadingIcon="FeatherChevronRight"
        />
        <div className="absolute w-screen">
          {showPicker && (
            <div
              style={{
                position: "absolute",
                zIndex: 100,
                background: "white",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                // left: "-50%",
                transform: "translateX(-26%) translateY(10%)",
              }}
            >
              <div className="flex justify-center">
                <DayPicker
                  mode="single"
                  numberOfMonths={2}
                  selected={date}
                  onSelect={(dateToGo) => {
                    if (dateToGo) {
                      onCustomNavigate(dateToGo);
                      setShowPicker(false); // Close picker after selection
                    }
                  }}
                  styles={{
                    day: {
                      cursor: "pointer",
                      // add background hover effect
                    },
                  }}
                  classNames={{
                    today: `text-millet-800 !rounded-lg`, // Highlight the current day
                    selected: `bg-millet-800 !text-white !rounded`, // Highlight the selected day
                    day: `${defaultClassNames.day} !rounded hover:!bg-millet-600`, // Adds hover effect
                    day_button: `${defaultClassNames.day_button} !rounded hover:!bg-millet-100`,
                    chevron: `${defaultClassNames.chevron} !fill-millet-800`,
                    root: `${defaultClassNames.root} rounded`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
