import * as React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Path } from "app/path";
import { getImportCsvClientsJobStatus } from "app/redux/clientsSlice";
import { LocalStorageKeys } from "app/dictionaries/localstorage";

interface ImportStatus {
  status: "processing" | "completed" | "failed";
  total: number;
  processed: number;
  progress: number;
  error?: string;
  completed_at?: string;
}

export const useClientCsvImportStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [importStatus, setImportStatus] = React.useState<ImportStatus | null>(
    null
  );
  const intervalRef = React.useRef<NodeJS.Timeout>();
  const redirectTimeoutRef = React.useRef<NodeJS.Timeout>();

  const checkStatus = React.useCallback(async () => {
    const importId = localStorage.getItem(LocalStorageKeys.CSV_IMPORT_JOB_ID);

    if (!importId) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      navigate(Path.CLIENT_IMPORT_CSV);
      return;
    }

    try {
      const response = await dispatch(
        getImportCsvClientsJobStatus(importId) as any
      ).unwrap();
      setImportStatus(response);

      if (response.status === "completed" || response.status === "failed") {
        if (intervalRef.current) clearInterval(intervalRef.current);
        localStorage.removeItem(LocalStorageKeys.CSV_IMPORT_JOB_ID);
      }
    } catch (error) {
      console.error("Failed to check import status:", error);
      if (intervalRef.current) clearInterval(intervalRef.current);
      localStorage.removeItem(LocalStorageKeys.CSV_IMPORT_JOB_ID);
      navigate(Path.CLIENTS);
    }
  }, [navigate, dispatch]);

  const handleRetry = React.useCallback(() => {
    if (redirectTimeoutRef.current) clearTimeout(redirectTimeoutRef.current);
    localStorage.removeItem(LocalStorageKeys.CSV_IMPORT_JOB_ID);
    navigate(Path.CLIENT_IMPORT_CSV);
  }, [navigate]);

  const handleBackToClients = React.useCallback(() => {
    if (redirectTimeoutRef.current) clearTimeout(redirectTimeoutRef.current);
    localStorage.removeItem(LocalStorageKeys.CSV_IMPORT_JOB_ID);
    navigate(Path.CLIENTS);
  }, [navigate]);

  React.useEffect(() => {
    checkStatus();
    intervalRef.current = setInterval(checkStatus, 2000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (redirectTimeoutRef.current) clearTimeout(redirectTimeoutRef.current);
    };
  }, [checkStatus]);

  return {
    importStatus,
    handleRetry,
    handleBackToClients,
  };
};
