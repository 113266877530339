// useLocations.tsx

import { createShop, selectShops, updateShop } from "app/redux/shopsSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchLocation as searchLocationFromApi } from "app/redux/mapboxSlice";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { getFormValues, getInvalidKeys } from "app/utils/getFormValues";
import { toast } from "react-toastify";
import { selectAccount } from "app/redux/accountSlice";

interface LocationSuggestion {
  name: string;
  latitude: number;
  longitude: number;
}

interface LocationState {
  addressInputText: string;
  locationSuggestions: LocationSuggestion[];
  selectedLocation?: LocationSuggestion;
  phoneValue: string;
}

export const useLocations = () => {
  const dispatch = useDispatch();
  const { paywallPermissions } = useSelector(selectAccount);
  const existingShops = useSelector(selectShops);
  const [shops, setShops] = useState<any[]>([]);
  const [locationStates, setLocationStates] = useState<
    Record<number, LocationState>
  >({});
  const [isCreatingNewShop, setIsCreatingNewShop] = useState(false);
  const [openShops, setOpenShops] = useState<Record<number, boolean>>({});
  const [isNewLocationModalOpen, setIsNewLocationModalOpen] = useState(false);

  const searchLocations = async (shopId: number, search: string) => {
    if (!search) {
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          locationSuggestions: [],
        },
      }));
      return;
    }
    try {
      const { data } = await dispatch(
        searchLocationFromApi({ query: search }) as any
      ).unwrap();
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          locationSuggestions: data,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchLocations = useCallback(
    debounce((shopId: number, search: string) => {
      searchLocations(shopId, search);
    }, 300),
    []
  );

  const onLocationInputChange = (shopId: number, search: string) => {
    setLocationStates((prev) => ({
      ...prev,
      [shopId]: {
        ...prev[shopId],
        addressInputText: search,
      },
    }));
    debouncedSearchLocations(shopId, search);
  };

  const onLocationSelect = (shopId: number, locationName: string) => {
    const selectedLocation = locationStates[shopId]?.locationSuggestions.find(
      (loc) => loc.name === locationName
    );
    if (selectedLocation) {
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          selectedLocation,
          addressInputText: selectedLocation.name,
          locationSuggestions: [],
        },
      }));
    }
  };

  const onShopArchiveToggle = async (shop: any, isArchived: boolean) => {
    const type = isArchived ? "unarchive" : "archive";
    const shouldToggleArchive = window.confirm(
      `Are you sure you want to ${type} the ${shop.name} location?`
    );
    if (!shouldToggleArchive) {
      return;
    }
    try {
      await dispatch(
        updateShop({
          id: shop.id,
          archivedAt: isArchived ? null : new Date().toISOString(),
        }) as any
      ).unwrap();

      setShops((prev) => prev.filter((prevShop) => prevShop.id !== shop.id));

      toast.success(`Location ${type}d successfully`);
    } catch (error) {
      console.log(error);
      toast.error(`Failed to ${type} location`);
    }
  };

  const setPhoneValue = (shopId: number, value: string) => {
    setLocationStates((prev) => ({
      ...prev,
      [shopId]: {
        ...prev[shopId],
        phoneValue: value,
      },
    }));

    setShops((prev) =>
      prev.map((shop) =>
        shop.id === shopId ? { ...shop, phone: value } : shop
      )
    );
  };

  const onSubmit = async (shopId: number, event: React.FormEvent) => {
    event.preventDefault();
    const locationState = locationStates[shopId];

    const formValues = getFormValues(event.target as HTMLFormElement);
    const { email, name, taxPercentage, address } = formValues;
    const phone = locationState.phoneValue;

    if (!email || !phone || !name || !address || !taxPercentage) {
      toast.error("Please fill in all fields");
      return;
    }

    if (phone.length < 11) {
      toast.error("Phone number doesn't seem to be right");
      return;
    }

    // Validate taxPercentage
    const taxPercentageValue = parseFloat(taxPercentage);
    if (
      isNaN(taxPercentageValue) ||
      taxPercentageValue < 0 ||
      taxPercentageValue > 100
    ) {
      toast.error("Please enter a valid tax percentage between 0 and 100");
      return;
    }

    const addressLine1 = address.split(",")[0];
    const addressLine2 = address.split(",").slice(1).join(",").trim();

    let latitude = 0;
    let longitude = 0;

    if (
      locationState.selectedLocation &&
      locationState.selectedLocation.name === address
    ) {
      latitude = locationState.selectedLocation.latitude;
      longitude = locationState.selectedLocation.longitude;
    }

    let payload = {
      id: shopId,
      email,
      phone,
      name,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      latitude: latitude || 0,
      longitude: longitude || 0,
      taxPercentage: taxPercentageValue,
    };

    try {
      await dispatch(updateShop(payload) as any).unwrap();
      toast.success(`Location: ${name} updated successfully`);
    } catch (error) {
      toast.error(`Failed to update location: ${name}`);
      console.log(error);
    }
  };

  const validShops = shops.filter((shop) => shop && locationStates[shop.id]);

  const toggleShop = (shopId: number) => {
    setOpenShops((prev) => ({ ...prev, [shopId]: !prev[shopId] }));
  };

  useEffect(() => {
    const initialLocationStates = existingShops.reduce((acc, shop) => {
      const address =
        shop.addressLine1 &&
        shop.addressLine2 &&
        `${shop.addressLine1}, ${shop.addressLine2 || ""}`;
      acc[shop.id] = {
        addressInputText: address || "",
        locationSuggestions: [],
        phoneValue: shop.phone || "",
      };
      return acc;
    }, {} as Record<number, LocationState>);

    setLocationStates(initialLocationStates);
    setShops(existingShops);
  }, [existingShops]);

  return {
    validShops,
    locationStates,
    onLocationInputChange,
    onLocationSelect,
    onSubmit,
    isCreatingNewShop,
    openShops,
    toggleShop,
    onShopArchiveToggle,
    isNewLocationModalOpen,
    setIsNewLocationModalOpen,
    setPhoneValue,
    paywallPermissions,
  };
};
