import * as React from "react";
import { perPageList, sortByList, useSales } from "./useSales";
import { Drawer } from "app/components/drawer/drawer";
import { SalesTable } from "./components/salesTable";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { OpenSaleScreen } from "./components/openSaleScreen/openSaleScreen";
import { ClosedSaleScreen } from "./components/closedSaleScreen/closedSaleScreen";
import { SaleStatusTypes } from "app/redux/salesSlice";
import { includes } from "lodash";
import { Loading } from "app/components/loading/loading";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { AsButton, Badge } from "app/subframe";
import { EmptyState } from "app/components/emptyState";
import { Icon } from "@subframe/core";

export interface SalesProps {}

export const Sales: React.FC<SalesProps> = () => {
  const {
    shouldShowDrawer,
    setShouldShowDrawer,
    rowsPerPage,
    onSaleRowClick,
    sortBy,
    setSortBy,
    setRowsPerPage,
    saleIdParam,
    statusParam,
    handleClientSearch,
    onOpenNewCheckoutDrawer,
    onResetDrawerScreens,
    saleStatusCondition,
    setSaleStatusCondition,
    loading,
    totalCount,
    onCloseDrawer,
    selectCorrespondingScreen,
    sales,
    employeeIdsToFilter,
    setEmployeeIdsToFilter,
    employees,
  } = useSales();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  const screenToShow =
    saleIdParam && statusParam === SaleStatusTypes.OPEN ? (
      <OpenSaleScreen
        handleClientSearch={handleClientSearch}
        saleStatusCondition={saleStatusCondition}
        onCloseDrawer={onCloseDrawer}
        setSaleStatusCondition={setSaleStatusCondition}
        selectCorrespondingScreen={selectCorrespondingScreen}
      />
    ) : saleIdParam &&
      includes([SaleStatusTypes.CLOSED, SaleStatusTypes.VOID], statusParam) ? (
      <ClosedSaleScreen setShouldShowDrawer={setShouldShowDrawer} />
    ) : !saleIdParam && !statusParam ? (
      <OpenSaleScreen
        handleClientSearch={handleClientSearch}
        saleStatusCondition={saleStatusCondition}
        onCloseDrawer={onCloseDrawer}
        setSaleStatusCondition={setSaleStatusCondition}
        selectCorrespondingScreen={selectCorrespondingScreen}
      />
    ) : null;

  return (
    <div
      className={classNames("flex flex-col p-4 gap-4 mobile:p-2", {
        "h-[85dvh]": isBelowSm && shouldShowDrawer,
      })}
    >
      {isBelowSm && (
        <span className="text-[20px] font-[500] leading-[30px] text-neutral-700">
          Sales
        </span>
      )}
      <Drawer
        isOpen={shouldShowDrawer}
        setIsOpen={setShouldShowDrawer}
        backdrop={false}
        hideCloseBtn={true}
        onClose={onResetDrawerScreens}
      >
        {screenToShow}
      </Drawer>
      <div className="flex items-center justify-between ">
        {isAboveSm ? (
          <div className="flex gap-2 items-center">
            <AsButton
              variant="neutral-secondary"
              size="md"
              onClick={() => onOpenNewCheckoutDrawer()}
            >
              <PlusIcon className="w-4 h-4 stroke-black" />
              <p className="font-semibold ">New Checkout</p>
            </AsButton>
          </div>
        ) : (
          <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-700">
            {totalCount} sales
          </span>
        )}
        <div className="flex gap-4">
          <div className="flex gap-4">
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  trailingIcon="FeatherChevronDown"
                  text="Sort"
                  size="sm"
                  className="text-neutral-900 hover:bg-millet-500"
                  loader={false}
                  disabled={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {sortByList.map((sb) => (
                  <DropdownItem
                    key={sb}
                    className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                    onClick={() => setSortBy(sb)}
                    endContent={
                      <CheckIcon
                        className={classNames("w-3 h-3 stroke-black", {
                          hidden: sb !== sortBy,
                        })}
                      />
                    }
                  >
                    {titleize(sb)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  leadingIcon={null}
                  trailingIcon="FeatherChevronDown"
                  className="text-neutral-900 hover:bg-millet-500"
                  text={`${rowsPerPage} per page`}
                  size="sm"
                  loader={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {perPageList.map((perPage) => (
                  <DropdownItem
                    className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                    key={perPage}
                    onClick={() => setRowsPerPage(perPage)}
                  >
                    {perPage} per page
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {isAboveSm && (
            <div className="flex items-center gap-2 relative">
              <p className="text-sm text-default-500">Filter by</p>
              <Dropdown>
                <DropdownTrigger>
                  <AsButton
                    variant="ghost"
                    leadingIcon={null}
                    trailingIcon="FeatherChevronDown"
                    className="text-neutral-900 hover:bg-millet-500"
                    text={"Employee"}
                    size="sm"
                    loader={false}
                  />
                </DropdownTrigger>
                <DropdownMenu selectionMode="multiple" closeOnSelect={false}>
                  {/* employeeIdsToFilter,
                 setEmployeeIdsToFilter, */}
                  {employees.map((employee) => {
                    const isSelected = employeeIdsToFilter.includes(
                      employee.id
                    );
                    return (
                      <DropdownItem
                        key={employee.id}
                        className="data-[hover=true]:!bg-millet-500 data-[focus=true]:!bg-millet-500"
                        onClick={() => {
                          // Check if the employee ID already exists in the filter
                          if (isSelected) {
                            // If it exists, remove it
                            setEmployeeIdsToFilter(
                              employeeIdsToFilter.filter(
                                (id) => id !== employee.id
                              )
                            );
                          } else {
                            // If it doesn't exist, add it
                            setEmployeeIdsToFilter([
                              ...employeeIdsToFilter,
                              employee.id,
                            ]);
                          }
                        }}
                      >
                        <div className="flex gap-1 items-center">
                          <Icon
                            name="FeatherCheck"
                            className={classNames({
                              invisible: !isSelected,
                            })}
                          />
                          {employee.name}
                        </div>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              {employeeIdsToFilter.length > 0 && (
                <Badge
                  variant="success"
                  className="absolute h-4 w-4 -top-1 -right-1 rounded-full z-10"
                >
                  {employeeIdsToFilter.length}
                </Badge>
              )}
            </div>
          )}
        </div>
      </div>
      {sales.length === 0 ? (
        <div className="w-full h-[calc(100dvh-200px)] flex justify-center items-center flex-col gap-10">
          <EmptyState />
          <AsButton
            text={"Create a new checkout"}
            onClick={() => onOpenNewCheckoutDrawer()}
          />
        </div>
      ) : (
        <SalesTable rowsPerPage={rowsPerPage} onSaleRowClick={onSaleRowClick} />
      )}
      {isBelowSm && (
        <AsButton
          variant="brand"
          leadingIcon="FeatherPlus"
          trailingIcon={null}
          text="New checkout"
          size="md"
          loader={false}
          shadow
          onClick={() => onOpenNewCheckoutDrawer()}
          className="fixed bottom-24 self-center z-50"
        />
      )}
    </div>
  );
};
