"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Brand input — https://app.subframe.com/feb895689ba0/library?component=Brand+input_3d37c8c8-b542-44dc-a8e9-ea42d8a1d158
 * AsInput — https://app.subframe.com/feb895689ba0/library?component=AsInput_f45dfa71-d2b1-4826-bd47-2993766763a3
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface BrandInputRootProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  trailingIcon?: boolean;
  className?: string;
  leading?: string;
  error?: boolean;
  trailing?: string;
  disabled?: boolean;
  showLeadingIcon?: boolean;
  placeholder?: string;
  leadingIcon?: SubframeCore.IconName;
  trailingComponent?: any;
  textPosition?: "left" | "right";
  inputClassName?: string;
}

const BrandInputRoot = React.forwardRef<HTMLInputElement, BrandInputRootProps>(
  function BrandInputRoot(
    {
      text,
      trailingIcon = false,
      leading,
      trailing,
      trailingComponent,
      error = false,
      disabled = false,
      placeholder,
      textPosition = "right",
      showLeadingIcon = false,
      leadingIcon = "FeatherPlus",
      className,
      inputClassName,
      ...otherProps
    }: BrandInputRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/3d37c8c8 flex h-14 w-full items-center gap-4 rounded-md border px-3 border-solid border-neutral-50 bg-white hover:border hover:border-solid hover:border-neutral-300 active:border active:border-solid active:border-millet-700 active:shadow-brand-ring focus-within:border focus-within:border-solid focus-within:border-millet-700 focus-within:shadow-brand-ring",
          {
            "border border-solid border-neutral-400 bg-neutral-200": disabled,
            "border border-solid border-error-500": error,
          },
          className
        )}
      >
        {/* Leading Icon */}
        {showLeadingIcon && (
          <SubframeCore.Icon
            className={SubframeCore.twClassNames(
              "text-body font-body text-neutral-900",
              { "text-neutral-400": disabled }
            )}
            name={leadingIcon}
          />
        )}

        {/* Leading Text */}
        {leading && (
          <span
            className={SubframeCore.twClassNames(
              "text-[14px] font-[500] leading-[20px] text-neutral-500 group-active/3d37c8c8:text-neutral-700 group-focus-within/3d37c8c8:text-neutral-700",
              { "text-neutral-400": disabled }
            )}
          >
            {leading}
          </span>
        )}

        {/* Input Element */}
        <input
          className={SubframeCore.twClassNames(
            "flex-grow w-full bg-transparent text-body font-body py-3.5 outline-none text-right text-[14px] font-[500] leading-[20px] mobile:text-[16px] text-neutral-700 group-active/3d37c8c8:text-neutral-700 group-focus-within/3d37c8c8:text-neutral-700 placeholder:text-neutral-500",
            {
              "text-neutral-400": disabled,
              "text-left": textPosition === "left",
              "text-right": textPosition === "right",
            },
            inputClassName
          )}
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          {...otherProps} // Spread input HTML attributes here
        />

        {/* Trailing Text */}
        {trailing && (
          <span
            className={SubframeCore.twClassNames(
              "text-[14px] font-[400] leading-[20px] text-neutral-500 text-right",
              { "text-neutral-400": disabled }
            )}
          >
            {trailing}
          </span>
        )}

        {/* Trailing Component */}
        {trailingComponent && (
          <div className="-mr-3"> {trailingComponent} </div>
        )}
      </div>
    );
  }
);

export const BrandInput = BrandInputRoot;
