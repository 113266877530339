import * as React from "react";
import { Navigate } from "react-router-dom";
import { useProtectedRoutes } from "./useProtectedRoutes";
import classnames from "classnames";
import styles from "./protectedRoutes.module.css";
import { Loading } from "app/components/loading/loading";
import { Navbar } from "app/components/navbar/navbar";
// import { ResellerNavbar } from "app/components/navbar/resellerNavbar";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { LocalStorageKeys } from "app/dictionaries/localstorage";
import { Path } from "app/path";
import { ImpersonatingBadge } from "app/components/impersonation/impersonationBadge";

export interface ProtectedRouteProps {
  children?: any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const {
    user,
    location,
    shouldShowNavbar,
    areScheduleRoutes,
    isReseller,
    reseller,
  } = useProtectedRoutes();
  const { isBelowSm, isAboveSm } = useBreakpoint("sm");
  const impersonationId = localStorage.getItem(
    LocalStorageKeys.IMPERSONATION_ID
  );

  const isImpersonating = React.useMemo(() => {
    return isReseller && impersonationId;
  }, [isReseller, impersonationId]);

  const isResellerButNotImpersonating = React.useMemo(() => {
    return isReseller && !impersonationId;
  }, [isReseller, impersonationId]);

  if (!user) {
    return <Loading type="dark" />;
  }

  if (isResellerButNotImpersonating && location.pathname === Path.HOME) {
    let url = Path.RESELLER_HOME as string;
    if (reseller.affiliateReferralId) {
      url += `?aff=${reseller.affiliateReferralId}`;
    }
    return <Navigate to={url} replace />;
  }

  if (isResellerButNotImpersonating) {
    return (
      <div className={styles.base}>
        <div className="relative overflow-hidden">
          <div className="flex h-full">
            {shouldShowNavbar && <Navbar reseller />}
            <div
              className={classnames("min-h-screen w-full", {
                "ml-14": isAboveSm,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.base}>
      <div className={classnames("relative overflow-hidden", {})}>
        <div className="flex h-full">
          {shouldShowNavbar && <Navbar />}
          <div
            className={classnames("min-h-screen", {
              "ml-14": shouldShowNavbar && isAboveSm,
              "w-full": !areScheduleRoutes,
              "w-screen": areScheduleRoutes,
            })}
          >
            {children}
            {isImpersonating && <ImpersonatingBadge />}
          </div>
        </div>
      </div>
    </div>
  );
};
