import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface ResellersSliceState {
  id: number;
  accounts: [];
  createdAt: string;
  updatedAt: string;
}

interface ResellerSliceReduxState {
  id?: number;
  name?: string;
  affiliateReferralId?: string;
  accounts: ResellersSliceState[];
  meta: {
    totalPages: number;
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
}

const initialState = {
  accounts: [],
  meta: {
    totalPages: 0,
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
  },
} as ResellerSliceReduxState;

// export const getResellers = createAsyncThunk(
//   "reseller/getResellers",
//   async () => {
//     const { data } = await http.get("/v1/resellers");
//     return data;
//   }
// );

export const getResellerAccounts = createAsyncThunk(
  "reseller/getResellerAccounts",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/resellers/accounts");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendSignUpLink = createAsyncThunk(
  "reseller/sendSignUpLink",
  async ({ email }: { email: string }, thunkAPI) => {
    try {
      const { data } = await http.post(
        "/v1/resellers/send_merchant_signup_email",
        { email }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMerchantForReseller = createAsyncThunk(
  "user/createMerchantForResellerStatus",
  async ({ token, businessName, toltReferral }: any, thunkAPI) => {
    try {
      const { data } = await http.post(
        `/v1/resellers/create_merchant_for_reseller`,
        {
          token,
          businessName,
          toltReferral,
        }
      );
      thunkAPI.dispatch(getResellerAccounts());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resellerSlice = createSlice({
  name: "reseller",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getResellerAccounts.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createMerchantForReseller.fulfilled, (state, action) => {
      const { account } = action.payload;
      state.accounts.push(account);
      return state;
    });
  },
});

export const selectReseller = (state: RootState) => state.reseller;
