import { Icon } from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";
import { Path } from "app/path";
import { AsBadge, AsButton, IconButton } from "app/subframe";
import { MenuItem } from "app/subframe/components/MenuItem";
import { UpdateAndGuides } from "app/subframe/components/UpdateAndGuides";
import { titleize } from "app/utils/string";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export interface MobileSettingProps {
  account: any;
  user: any;
  onLogout: () => void;
}

export const MobileSetting: React.FC<MobileSettingProps> = ({
  account,
  user,
  onLogout,
}) => {
  const navigate = useNavigate();
  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 !pt-4 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
      <div className="flex w-112 px-2 items-center justify-end gap-4 mobile:h-auto mobile:w-full mobile:flex-none">
        <IconButton
          disabled={false}
          variant="neutral-tertiary"
          size="small"
          icon="FeatherX"
          loading={false}
          onClick={() => {
            navigate(Path.HOME);
          }}
        />
      </div>
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-6 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center gap-4 mobile:h-auto mobile:w-full mobile:flex-none mobile:flex-row mobile:gap-2">
          <Avatar size={56} src={account.logo} name={account.name} />
          <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-4 mobile:h-auto mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:items-start mobile:justify-center mobile:gap-2">
            <span className="text-body font-body text-default-font">
              {account.name}
            </span>
            <AsBadge variant="success" icon={null} iconRight={null}>
              {titleize(user.role)}
            </AsBadge>
          </div>

          <UpdateAndGuides className="w-fit" />
        </div>
        <MenuItem
          className="h-auto w-full flex-none"
          disabled
          icon="FeatherUserCog"
          text="User settings"
        />
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100 mobile:bg-neutral-100" />
        <MenuItem
          className="h-auto w-full flex-none"
          disabled
          icon="FeatherSettings"
          text="Business settings"
        />
        <MenuItem
          className="h-auto w-full flex-none"
          disabled
          icon="FeatherUser"
          text="Staff"
        />
        <MenuItem
          className="h-auto w-full flex-none"
          disabled
          icon="FeatherHelpingHand"
          text="Services"
        />
        <MenuItem
          className="h-auto w-full flex-none"
          icon="FeatherCreditCard"
          text="Billing"
          onClick={() => navigate(Path.BILLING)}
        />
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
        <div
          onClick={() => onLogout()}
          className="flex h-10 w-full flex-none items-center justify-center gap-2 rounded-md px-3 py-2"
        >
          <span className="grow shrink-0 basis-0 text-body-bold font-body-bold text-neutral-900">
            Log out
          </span>
          <Icon
            className="text-body font-body text-neutral-900"
            name="FeatherLogOut"
          />
        </div>
      </div>
    </div>
  );
};
