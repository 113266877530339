import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { RootState } from "./store";
import { getClients } from "./clientsSlice";
import { SubscriptionPlansType } from "app/dictionaries/plans";

export interface AccountSliceState {
  id: number;
  name: string;
  website: string;
  description: string;
  employees: any[];
  services: any[];
  key: string;
  bookingLink: string;
  onboarding: any;
  teamSize: string;
  serviceProviders: any[];
  shops: any[];
  acceptBlueTokenizationApi: string;
  acceptBlueApiKey: string;
  acceptBlueApiPin: string;
  logo: any;
  landingImages: any[];
  selectedLandingImageLink: string;
  requireCreditCardForBooking: boolean;
  enableCashPaymentForBooking: boolean;
  resellerId?: number | null;
  reseller?: any;
  requestReview: boolean;
  reviewLink?: string;
  archivedAt?: string;
  blacklist: any[];
  waiverEnabled: boolean;
  waiverRequiredForEveryBooking: boolean;
  waiver?: {
    id: number;
    url: string;
    name: string;
  };
  consentEnabled: boolean;
  consentForm?: {
    id: number;
    url: string;
    name: string;
    serviceIds: number[];
  };
  dualPricingEnabled: boolean;
  subscription: {
    plan: SubscriptionPlansType;
    inActiveAt: string;
    status: string;
  };
  paywallPermissions: {
    addLocation: boolean;
    addStaff: boolean;
    staffLimit: number;
    dualPricing: boolean;
    reviewLink: boolean;
    bookingUrl: boolean;
    processPayments: boolean;
  };
  apiKeysFilled: boolean;
  stripeCustomerId: string;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as AccountSliceState;

export const getAccount = createAsyncThunk(
  "account/getAccountStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/accounts`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccountStatus",
  async (account: Partial<AccountSliceState>, thunkAPI) => {
    try {
      let data;
      if (account.logo || account.landingImages) {
        const formData = new FormData();
        if (account.logo) formData.append("account[logo]", account.logo);
        if (account.landingImages) {
          account.landingImages.forEach((image, index) => {
            formData.append(`account[landing_images][]`, image);
          });
        }
        for (const [key, value] of Object.entries(account)) {
          if (key !== "logo" && key !== "landingImages") {
            formData.append(`account[${key}]`, value as string);
          }
        }
        data = await http.patch(`/v1/accounts`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        data = await http.patch(`/v1/accounts`, account);
      }
      return data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const blacklistFromAccount = createAsyncThunk(
  "account/blacklistFromAccountStatus",
  async (
    {
      identifier,
      identifierType,
      reason,
    }: {
      identifier: string;
      identifierType: string;
      reason?: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/accounts/blacklist`, {
        identifier,
        identifierType,
        reason,
      });
      thunkAPI.dispatch(getAccount());
      thunkAPI.dispatch(getClients({}));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unblackListFromAccount = createAsyncThunk(
  "account/unblackListFromAccountStatus",
  async (
    {
      identifier,
      identifierType,
    }: {
      identifier: string;
      identifierType: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/accounts/unblacklist`, {
        identifier,
        identifierType,
      });
      thunkAPI.dispatch(getAccount());
      thunkAPI.dispatch(getClients({}));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStripeCustomerClientSecret = createAsyncThunk(
  "account/getStripeCustomerClientSecret",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/accounts/get_stripe_customer_client_secret`
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStripeCustomerPortal = createAsyncThunk(
  "account/getStripeCustomerPortal",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/accounts/get_stripe_customer_portal`
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccount.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(updateAccount.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectAccount = (state: RootState) => state.account;
