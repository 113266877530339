import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "app/path";
import { ProtectedRoute } from "./protectedRoutes";
import { NotFound } from "app/notFound/notFound";
import { AuthContextProvider } from "app/context/AuthContext";
import { Home as ResellerHome } from "app/reseller/home/home";
import { Signin } from "app/auth/signin/signin";
import { Settings } from "app/settings/settings";
import { BusinessServices } from "app/businessServices/businessServices";
import { Staff } from "app/staff/staff";
import { Clients } from "app/clients/clients";
import { Schedules } from "app/schedules/schedules";
import { Signup } from "app/auth/signup/signup";
import { Onboarding } from "app/onboarding/onboarding";
import { AcceptInvite } from "app/invite/acceptInvite/acceptInvite";
import { Sales } from "app/sales/sales";
import { User } from "app/user/user";
import { Playground } from "app/playground/playground";
import { ConfirmAppointment } from "app/confirmAppointment/confirmAppointment";
import { ClientImportCsv } from "app/clients/clientImportCsv/clientImportCsv";
import { ClientCsvImportStatus } from "app/clients/clientImportCsv/clientCsvImportStatus";
import { Billing } from "app/billing/billing";

export const Router: React.FC = () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path={Path.HOME}
          element={
            <ProtectedRoute>
              <Schedules />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SETTINGS}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.BUSINESS_SERVICES}
          element={
            <ProtectedRoute>
              <BusinessServices />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.STAFF}
          element={
            <ProtectedRoute>
              <Staff />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CLIENTS}
          element={
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.SALES}
          element={
            <ProtectedRoute>
              <Sales />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.ONBOARDING}
          element={
            <ProtectedRoute>
              <Onboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.USER}
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CONFIRM_APPOINTMENT}
          element={
            <ProtectedRoute>
              <ConfirmAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.RESELLER_HOME}
          element={
            <ProtectedRoute>
              <ResellerHome />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CLIENT_IMPORT_CSV}
          element={
            <ProtectedRoute>
              <ClientImportCsv />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.CLIENT_IMPORT_CSV_STATUS}
          element={
            <ProtectedRoute>
              <ClientCsvImportStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path={Path.BILLING}
          element={
            <ProtectedRoute>
              <Billing />
            </ProtectedRoute>
          }
        />
        <Route path={Path.LOGIN} element={<Signin />} />
        <Route path={Path.SIGNUP} element={<Signup />} />
        <Route path={Path.ACCEPT_INVITE} element={<AcceptInvite />} />
        <Route path={"/playground"} element={<Playground />} />
        {/* <Route path={Path.SIGNUP} element={<Signup />} /> */}
        {/* <ProtectedRoute path={Path.SETTINGS} element={<Settings />} /> */}
        {/* <Route path={Path.TERMS} element={<Terms />} /> */}
        {/* <Route path={Path.POLICY} element={<Policy />} /> */}
      </Routes>
    </AuthContextProvider>
  );
};
