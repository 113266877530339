import { useEffect } from "react";
import { selectUser } from "app/redux/userSlice";
import { useSelector } from "react-redux";

export const useCrispChat = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    if (window.$crisp && user?.id) {
      window.$crisp.push(["do", "chat:show"]);
      // Set user information if available
      if (user.email) {
        window.$crisp.push(["set", "user:email", user.email]);
      }
      if (user.name) {
        window.$crisp.push(["set", "user:nickname", user.name]);
      }
    }
  }, [user?.id]);

  return {};
};
