import { ClientsSliceState } from "app/redux/clientsSlice";
import { selectReseller } from "app/redux/resellerSlice";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SortDescriptor } from "react-stately";

export const useResellerAccountsTable = ({
  onAccountImpersonate,
}: {
  onAccountImpersonate: (id: number) => void;
}) => {
  const {
    accounts,
    meta: { totalCount },
  } = useSelector(selectReseller);
  const query = useQuery();
  const pageParam = query.get("page");
  const [page, setPage] = useState(pageParam ? Number(pageParam) : 1);
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>(
    {}
  );

  const onPageChange = (page: number) => {
    setPage(page);
    query.set("page", page.toString());
  };

  const onImpersonate = (id: number) => {
    onAccountImpersonate(id);
  };

  const sortedItems = React.useMemo(() => {
    // First sort by demo status
    const sortedByDemo = [...accounts].sort((a: any, b: any) => {
      const aIsDemo = a.name?.toLowerCase().includes("demo") ?? false;
      const bIsDemo = b.name?.toLowerCase().includes("demo") ?? false;

      if (aIsDemo && !bIsDemo) return -1;
      if (!aIsDemo && bIsDemo) return 1;
      return 0;
    });

    // If no additional sorting is needed, return the demo-sorted array
    if (accounts.length === 0 || !sortDescriptor.column) return sortedByDemo;

    // Apply the regular sorting while maintaining demo priority
    return sortedByDemo.sort((a: any, b: any) => {
      // If one is demo and other isn't, maintain their relative positions
      const aIsDemo = a.name?.toLowerCase().includes("demo");
      const bIsDemo = b.name?.toLowerCase().includes("demo");
      if (aIsDemo !== bIsDemo) return 0;

      // Regular sorting for items in the same category (demo or non-demo)
      const first = a[sortDescriptor.column as keyof any] as number;
      const second = b[sortDescriptor.column as keyof any] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, accounts]);

  return {
    accounts,
    totalCount,
    page,
    onPageChange,
    sortedItems,
    onImpersonate,
  };
};
