import { Icon } from "@subframe/core";
import { formatBytes } from "app/utils/formatBytes";
import * as React from "react";

export interface UploadCsvProps {
  getRootProps: () => any;
  getInputProps: () => any;
  csvFile: File | null;
  fileHasBeenUploaded: boolean;
}

export const UploadCsv: React.FC<UploadCsvProps> = ({
  getRootProps,
  getInputProps,
  csvFile,
  fileHasBeenUploaded,
}) => {
  return (
    <div
      {...getRootProps()}
      className="border-2 border-neutral-200 rounded-lg py-10 p-4 cursor-pointer border-dashed"
    >
      <input {...getInputProps()} />
      <div className="flex flex-col gap-4 items-center justify-center">
        {fileHasBeenUploaded ? (
          <>
            <div className="flex items-center gap-4">
              <Icon
                name="FeatherCheckCircle2"
                className="h-6 text-success-600 scale-125"
              />
              <p className="text-success-600 font-medium">File processed</p>
            </div>

            <p className="text-neutral-900 text-2xl">{csvFile?.name}</p>
            <p className="text-neutral-500 text-sm">
              {formatBytes(csvFile!.size)}
            </p>
          </>
        ) : (
          <>
            <Icon
              name="FeatherUpload"
              className="scale-[2.5] font-thin h-12 text-neutral-500"
            />
            <p className="text-neutral-500 text-2xl text-center">
              Click or drop here to upload
            </p>
          </>
        )}
      </div>
    </div>
  );
};
