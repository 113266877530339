import * as React from "react";
import { Listbox, ListboxItem } from "@nextui-org/react";
import { SettingTypes, useSettings } from "./useSettings";
import { BusinessDetails } from "./businessDetails/businessDetails";
import { Locations } from "./locations/locations";
import classNames from "classnames";
import { Branding } from "./branding/branding";
import { ApiKeys } from "./apiKeys/apiKeys";
import { BusinessPolicies } from "./businessPolicies/businessPolicies";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { MobileSetting } from "./mobileSetting/mobileSettings";
import { SubscriptionSuccessfulModal } from "app/billing/components/subscriptionSuccessfulModal";
import { SubscriptionTierType } from "app/dictionaries/plans";

export interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = (props) => {
  const {
    onMenuPress,
    path,
    logout,
    account,
    user,
    showSubscriptionSuccessModal,
    setShowSubscriptionSuccessModal,
    newlySubscribedPlan,
  } = useSettings();
  const { isBelowSm } = useBreakpoint("sm");

  const view = () => {
    switch (path) {
      case SettingTypes.BUSINESS_DETAILS:
        return <BusinessDetails />;
      case SettingTypes.LOCATION:
        return <Locations />;
      case SettingTypes.BRANDING:
        return <Branding />;
      case SettingTypes.BUSINESS_POLICIES:
        return <BusinessPolicies />;
      case SettingTypes.API_KEYS:
        return <ApiKeys />;
      default:
        return <div>Business Details</div>;
    }
  };

  if (isBelowSm) {
    return <MobileSetting onLogout={logout} account={account} user={user} />;
  }

  return (
    <>
      <SubscriptionSuccessfulModal
        open={showSubscriptionSuccessModal}
        onOpenChange={setShowSubscriptionSuccessModal}
        newlySubscribedPlan={newlySubscribedPlan as SubscriptionTierType}
      />
      <div className="w-[744px] flex items-start gap-10 mx-auto mt-10">
        <div className="relative w-1/3 h-fit">
          <Listbox
            className="p-2 justify-start items-start w-[200px] rounded shadow-dialog-shadow fixed top-10 z-10 bg-white"
            aria-label="Actions"
            onAction={(key: any) => onMenuPress(key)}
            classNames={{
              list: "gap-2",
            }}
          >
            <ListboxItem
              classNames={{
                base: `${
                  path === SettingTypes.BUSINESS_DETAILS ? "bg-millet-500" : ""
                } rounded-sm !p-2 hover:!bg-millet-400`,
              }}
              key={SettingTypes.BUSINESS_DETAILS}
            >
              <p
                className={classNames(
                  path === SettingTypes.BUSINESS_DETAILS ? "font-semibold" : ""
                )}
              >
                Business Details
              </p>
            </ListboxItem>
            <ListboxItem
              classNames={{
                base: `${
                  path === SettingTypes.LOCATION ? "bg-millet-500" : ""
                } rounded-sm !p-2 hover:!bg-millet-400`,
              }}
              key={SettingTypes.LOCATION}
            >
              <p
                className={classNames(
                  path === SettingTypes.LOCATION ? "font-semibold" : ""
                )}
              >
                Location
              </p>
            </ListboxItem>
            <ListboxItem
              key={SettingTypes.BRANDING}
              classNames={{
                base: `${
                  path === SettingTypes.BRANDING ? "bg-millet-500" : ""
                } rounded-sm !p-2 hover:!bg-millet-400`,
              }}
            >
              <p
                className={classNames(
                  path === SettingTypes.BRANDING ? "font-semibold" : ""
                )}
              >
                Branding
              </p>
            </ListboxItem>
            <ListboxItem
              key={SettingTypes.BUSINESS_POLICIES}
              classNames={{
                base: `${
                  path === SettingTypes.BUSINESS_POLICIES ? "bg-millet-500" : ""
                } rounded-sm !p-2 hover:!bg-millet-400`,
              }}
            >
              <p
                className={classNames(
                  path === SettingTypes.BUSINESS_POLICIES ? "font-semibold" : ""
                )}
              >
                Business Policies
              </p>
            </ListboxItem>
            <ListboxItem
              key={SettingTypes.API_KEYS}
              classNames={{
                base: `${
                  path === SettingTypes.API_KEYS ? "bg-millet-500" : ""
                } rounded-sm !p-2 hover:!bg-millet-400`,
              }}
            >
              <p
                className={classNames(
                  path === SettingTypes.API_KEYS ? "font-semibold" : ""
                )}
              >
                API Keys
              </p>
            </ListboxItem>
          </Listbox>
        </div>
        <div className="w-full">{view()}</div>
      </div>
    </>
  );
};
