import { LocalStorageKeys } from "app/dictionaries/localstorage";
import { Path } from "app/path";
import { selectAccount } from "app/redux/accountSlice";
import { RootState } from "app/redux/store";
import { AsIconButton } from "app/subframe";
import * as React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

export interface ImpersonatingBadgeProps {}

export const ImpersonatingBadge: React.FC<ImpersonatingBadgeProps> = ({}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const account = useSelector(selectAccount);

  const onStopImpersonation = () => {
    setIsLoading(true);
    localStorage.removeItem(LocalStorageKeys.IMPERSONATION_ID);
    window.location.href = Path.HOME;
  };
  return (
    <Draggable>
      <div
        className="flex items-center gap-2 bottom-24 right-6 z-[9999] fixed cursor-grab active:cursor-grabbing"
        style={{
          zIndex: 9999,
        }}
      >
        <div className="border border-neutral-100 rounded-lg shadow-lg bg-neutral-25">
          <div className="flex items-center p-4">
            <p>
              Acting as <span className="font-bold">{account?.name}</span>
            </p>
          </div>
        </div>
        <AsIconButton
          leadingIcon={"FeatherLogOut"}
          leadingIconClassName="scale-150"
          className="shadow-lg rounded-lg"
          variant="brand"
          size="lg"
          loader={isLoading}
          onClick={() => {
            onStopImpersonation();
          }}
        />
      </div>
    </Draggable>
  );
};
