import * as React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectReseller, sendSignUpLink } from "app/redux/resellerSlice";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { getFormValues } from "app/utils/getFormValues";

export const useCreateMerchantModal = ({
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
}) => {
  const { handleCreateMerchantForReseller } = UserAuth() as UserContextType;
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const [email, setEmail] = useState("");
  const [isSendingSignUpLink, setIsSendingSignUpLink] = useState(false);
  const [isCreatingMerchant, setIsCreatingMerchant] = useState(false);

  const onSendSignUpLink = async () => {
    try {
      setIsSendingSignUpLink(true);
      console.log("sending sign up link", email);
      await dispatch(sendSignUpLink({ email }) as any).unwrap();
      setIsSendingSignUpLink(false);
      toast.success(`A sign up link sent to ${email}`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to send sign up link");
    } finally {
      setIsSendingSignUpLink(false);
    }
  };

  const onCreateMerchant = async (event: any) => {
    event.preventDefault();
    setIsCreatingMerchant(true);
    try {
      const { email, password, passwordConfirmation, businessName } =
        getFormValues(event.target);

      if (password !== passwordConfirmation) {
        toast.error("Passwords do not match");
        setIsCreatingMerchant(false);
        return;
      }

      if (password.length < 8) {
        toast.error("Password must be at least 8 characters");
        setIsCreatingMerchant(false);
        return;
      }

      await handleCreateMerchantForReseller({
        email,
        password,
        businessName,
        toltReferral: window.tolt_referral,
      });

      toast.success(`Merchant ${businessName} created successfully!`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to create merchant");
    } finally {
      setIsCreatingMerchant(false);
      onOpenChange(false);
    }
  };

  return {
    email,
    setEmail,
    onSendSignUpLink,
    isSendingSignUpLink,
    reseller,
    onCreateMerchant,
    isCreatingMerchant,
  };
};
